/* eslint-disable mosaic-js/no-coalesce-true */
import { ReactNode, useCallback, useRef, useState } from 'react'
import { cn } from 'msutils/classnames'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { Select } from 'antd'
import InputBase, { InputBaseUtils } from 'compass-local/InputBase'
import { Arrow, Chevron, ErrorIcon, Plus } from 'compass-local/legacy/icons'
import { theme } from 'styles/theme'
import useScreenSize from 'compass/theme/useScreenSize'
import { t } from 'content'
import useHtmlId from 'compass-local/utils/useHtmlId'
import Typography from 'compass/data/Typography'
import { TypographyUtils } from 'compass/data/Typography/utils'
import Divider from 'compass-local/Divider'
import Spinner from 'compass-local/Spinner'
import { useControlledOrUncontrolled } from 'utils/misc'
import { Colorset } from 'compass-local/utils/colorset'
import SearchInput from 'compass-local/SearchInput'
import CloseCircle from 'compass-local/legacy/icons/CloseCircle'
import { MSForm } from 'utils/form'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { Action } from 'utils/actions'
import * as SelectInputUtils from '../utils'
import { OptionProp } from '../utils'
import { OptionLabel } from '../component-utils'

const DefaultColorset: Colorset = {
  text: 'text-th-text',
}

type Props<TValue, TMetadata> = InputProps<TValue | null> &
  InputBaseUtils.ExternalProps & {
    options: TMetadata[] | null
    getId: (value: TValue) => string
    getTitle: (value: TValue) => string
    getOptionProps: (metadata: TMetadata) => OptionProp<TValue>
    footer?: ReactNode
    colors?: Colorset
    fontWeight?: string
    search?: [string, (newValue: string) => void]
    filter?: (search: string, metadata: TMetadata) => boolean
    placeholder?: string
    isLoading?: boolean
    onCreate?: (search: string) => void
    disableSearch?: boolean
    hidden?: boolean
    titleVariant?: TypographyUtils.Variant
    disableSortByTitle?: boolean
    dropdownHeight?: number
    createNewMessage?: string
    emptyCreateTitle?: string
  }

export default function SelectInput<TValue, TMetadata>(props: Props<TValue, TMetadata>) {
  const {
    title,
    value,
    update,
    focus,
    blur,
    error,
    willUpdate,
    didUpdate,
    disabled,
    options,
    filter,
    placeholder = t('Select an option'),
    getId,
    getTitle,
    getOptionProps,
    footer,
    hidden,
    isLoading,
    colors = DefaultColorset,
    fontWeight = 'font-medium',
    titleVariant = 'bodybold',
    search: controlledSearch,
    disableSortByTitle,
    disableSearch,
    onCreate,
    dropdownHeight,
    createNewMessage = t('Create new'),
    emptyCreateTitle,
  } = getInputProps(props)

  const sz = useScreenSize()
  const inputRef = useRef<SelectInputUtils.SelectRef | null>(null)
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [cursorOnFooter, setCursorOnFooter] = useState(false)
  // eslint-disable-next-line react/hook-use-state
  const [search, setSearch] = useControlledOrUncontrolled(controlledSearch, useState(''))
  const [isFocused, setIsFocused] = useState(false)
  const id = useHtmlId()

  const updateInLifecycle = useCallback(
    async (newValue: TValue | null) => {
      await willUpdate?.(value, newValue)
      update?.(newValue)
      didUpdate?.(value, newValue)
    },
    [willUpdate, update, didUpdate, value],
  )

  const optionsIsNull = options === null
  const buildMenu = useCallback(
    (baseMenu: ReactNode) => (
      <>
        <div className={cn('min-w-min', dropdownVisible && 'test-select-menu')}>{baseMenu}</div>
        {((onCreate && !optionsIsNull) || footer) && (
          <div
            className="vflex gap-1 cursor-default"
            onMouseEnter={() => setCursorOnFooter(true)}
            onMouseLeave={() => setCursorOnFooter(false)}
          >
            {onCreate && (
              <>
                <Divider />
                <div
                  className="flex gap-3 items-center py-3 px-3 hover:bg-th-orange-light2 rounded-2 cursor-pointer"
                  onClick={async () => {
                    if (value) {
                      await updateInLifecycle(null)
                    }
                    onCreate(search)
                    setDropdownVisible(false)
                    inputRef.current?.blur()
                  }}
                >
                  <Plus height={14} />
                  <Typography>
                    {createNewMessage}
                    {search ? ` "${search}"` : ''}
                  </Typography>
                </div>
              </>
            )}
            {footer && (
              <>
                <Divider />
                <div
                  className="py-2 px-3 hover:bg-th-orange-light2 rounded-2 cursor-pointer"
                  onClick={() => {
                    setDropdownVisible(false)
                    inputRef.current?.blur()
                  }}
                >
                  {footer}
                </div>
              </>
            )}
          </div>
        )}
      </>
    ),
    [
      value,
      onCreate,
      search,
      inputRef,
      optionsIsNull,
      footer,
      dropdownVisible,
      createNewMessage,
      updateInLifecycle,
    ],
  )

  const sortedOptions = (options ?? []).sort((a, b) =>
    disableSortByTitle
      ? 1
      : getTitle(getOptionProps(a).value) < getTitle(getOptionProps(b).value)
      ? -1
      : 1,
  )

  const notFoundContent =
    isLoading && options === null ? (
      <div className="flex justify-center py-3 text-th-text-hint">
        <Spinner w="w-4" h="h-4" />
      </div>
    ) : (
      <Typography className="w-full flex justify-center text-th-text-hint py-3 cursor-default">
        {t('No results')}
      </Typography>
    )

  const showCreateFirst = onCreate && !optionsIsNull && options.length === 0 && emptyCreateTitle

  if (hidden) return null
  return (
    <InputBase
      {...props}
      cursorType={showCreateFirst ? 'cursor-pointer' : 'cursor-text'}
      isFocused={!!containerRef?.contains(document.activeElement) || isFocused}
      setFocus={() => (showCreateFirst ? onCreate('') : inputRef.current?.focus())}
      {...(error &&
        !isFocused && {
          annotation: {
            active: true,
            message: error,
            color: 'red',
            icon: <ErrorIcon height={12} />,
          },
        })}
    >
      {sz === 'sm' ? (
        showCreateFirst ? (
          <LinkButtonDONOTUSE
            onClick={() => onCreate('')}
            endIcon={<Arrow height={14} className="rotate-90" />}
          >
            {emptyCreateTitle}
          </LinkButtonDONOTUSE>
        ) : (
          <>
            <div
              id={id}
              ref={setContainerRef}
              className={cn(
                'h-[17px] flex gap-3 justify-between items-center',
                value ? colors.text ?? DefaultColorset.text : 'text-th-text-hint',
                fontWeight,
              )}
              onClick={() => (disabled ? null : setDropdownVisible(true))}
            >
              {value === null ? placeholder : getTitle(value)}
              {!disabled && value ? (
                <CloseCircle
                  className="text-th-text-hint hitbox-lg"
                  onClick={(e) => {
                    e.stopPropagation()
                    updateInLifecycle(null)
                  }}
                />
              ) : (
                <Chevron className="text-th-text-hint rotate-90" height={14} />
              )}
            </div>
            <MSForm.Drawer
              title={title}
              inlineTitle
              isActive={dropdownVisible}
              setInactive={() => setDropdownVisible(false)}
              reverseFooterDONOTUSE
              actions={[
                Action.button(`${createNewMessage} ${search ? ` "${search}"` : ''}`, {
                  icon: 'plus',
                  theme: 'text-bordered',
                  qualify: () => !!onCreate,
                  onClick: async () => {
                    if (value) {
                      await updateInLifecycle(null)
                    }
                    onCreate?.(search)
                    setDropdownVisible(false)
                    setSearch('')
                    setIsFocused(false)
                  },
                }),
              ]}
              inlineFooter={<div className="flex justify-center p-2">{footer}</div>}
            >
              <div className="vflex gap-3">
                {!disableSearch && (options?.length ?? 0) >= 10 && (
                  <SearchInput value={search} update={setSearch} />
                )}
                <div className="vflex gap-1 -mx-3">
                  {(sortedOptions ?? [])
                    .filter((o) => filter?.(search, o) ?? true)
                    .map((o) => {
                      const optionProps = getOptionProps(o)
                      return (
                        <div
                          key={getId(optionProps.value)}
                          className={cn(
                            'p-3 rounded-8 hover:bg-th-orange-light2',
                            optionProps.disabled && 'opacity-50',
                            value &&
                              getId(optionProps.value) === getId(value) &&
                              'bg-th-orange-light2',
                          )}
                          onClick={async () => {
                            if (!optionProps.disabled) {
                              await updateInLifecycle(optionProps.value)
                              setDropdownVisible(false)
                              setSearch('')
                              setIsFocused(false)
                            }
                          }}
                        >
                          <OptionLabel
                            {...optionProps}
                            getTitle={getTitle}
                            titleVariant={titleVariant}
                          />
                        </div>
                      )
                    })}
                  {(sortedOptions ?? []).filter((o) => filter?.(search, o) ?? true).length === 0 &&
                    notFoundContent}
                </div>
              </div>
            </MSForm.Drawer>
          </>
        )
      ) : (
        <div
          id={id}
          ref={setContainerRef}
          className={cn(
            showCreateFirst ? '-my-0.5' : '-my-[7.5px] -mx-3',
            colors.text ?? DefaultColorset.text,
            fontWeight,
          )}
        >
          {showCreateFirst ? (
            <LinkButtonDONOTUSE
              onClick={() => onCreate('')}
              endIcon={<Arrow height={14} className="rotate-90" />}
            >
              {emptyCreateTitle}
            </LinkButtonDONOTUSE>
          ) : (
            <>
              <Select
                open={dropdownVisible}
                searchValue={search}
                showSearch={!disableSearch}
                onSearch={disableSearch ? undefined : setSearch}
                filterOption={(q, option) => (option ? filter?.(q, option.metadata) : null) ?? true}
                disabled={disabled}
                allowClear
                rootClassName="w-full"
                value={value === null ? null : getTitle(value)}
                onClick={(e) => e.stopPropagation()}
                onSelect={async (_, option) => {
                  await updateInLifecycle(getOptionProps(option.metadata).value)
                  setIsFocused(false)
                  inputRef.current?.blur()
                }}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter' && search && onCreate) {
                    const filteredOptions = (options ?? []).filter(
                      (o) => filter?.(search, o) ?? true,
                    )
                    if (filteredOptions.length === 0) {
                      if (value) {
                        await updateInLifecycle(null)
                      }
                      onCreate(search)
                      setDropdownVisible(false)
                      setIsFocused(false)
                      inputRef.current?.blur()
                    }
                  }
                }}
                onClear={() => updateInLifecycle(null)}
                onFocus={() => {
                  focus?.()
                  setIsFocused(true)
                }}
                onBlur={() => {
                  blur?.()
                  setIsFocused(false)
                }}
                bordered={false}
                options={sortedOptions.map((o) => {
                  const optionProps = getOptionProps(o)
                  return {
                    value: getId(optionProps.value),
                    disabled: optionProps.disabled,
                    label: (
                      <OptionLabel
                        {...optionProps}
                        getTitle={getTitle}
                        titleVariant={titleVariant}
                      />
                    ),
                    metadata: o,
                    className: cursorOnFooter && '!bg-transparent',
                  }
                })}
                showAction={['focus', 'click']}
                ref={inputRef}
                loading={isLoading}
                popupMatchSelectWidth={false}
                popupClassName="w-min z-[10040]"
                onDropdownVisibleChange={setDropdownVisible}
                listHeight={dropdownHeight ?? 256}
                dropdownRender={buildMenu}
                suffixIcon={<Chevron height={12} className="rotate-90 text-th-warmgrey-1" />}
                notFoundContent={notFoundContent}
                placeholder={placeholder}
              />
              <style>{`
#${id} .ant-select-selection-placeholder {
  color: ${(theme?.colors as any)?.th?.text?.hint} !important;
  font-weight: 500;
  opacity: 1;
}

#${id} .ant-select-selector {
  font-weight: inherit !important;
  ${disabled ? '' : 'color: inherit !important;'}
}

#${id} .ant-select {
  font-weight: inherit !important;
  color: inherit !important;
}

#${id} .ant-select-selection-item {
  font-weight: inherit !important;
  color: inherit !important;
}

#${id} .ant-select-selection-search-input {
  font-size: ${(theme?.fontSize as any)?.base?.at(0)};
}
        `}</style>
            </>
          )}
        </div>
      )}
    </InputBase>
  )
}
