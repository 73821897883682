/* eslint-disable mosaic-js/unnamed-args */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import * as Utils from '../utils/generator-utils'
import * as G from './raw-axios'
import {
  PublicLogoViewOutput,
  ListAdditionalContactsParams,
  ListAdditionalContacts200,
  AdditionalContact,
  AdditionalContactCreate,
  AdditionalContactPatch,
  ListBusinessParams,
  ListBusiness200,
  Business,
  BusinessCreate,
  BusinessPatch,
  ListEmployeeCommunicationConfigsParams,
  ListEmployeeCommunicationConfigs200,
  EmployeeCommunicationConfig,
  EmployeeCommunicationConfigPatch,
  ListEmployeesParams,
  ListEmployees200,
  Employee,
  EmployeeCreate,
  EmployeePatch,
  ListGCEmailsParams,
  ListGCEmails200,
  GCEmail,
  GCEmailCreate,
  ListOutboundInvitesParams,
  ListOutboundInvites200,
  OutboundInvite,
  OutboundInviteCreate,
  ListPayeeContactsParams,
  ListPayeeContacts200,
  PayeeContact,
  GetArchiveRestrictionsOutput,
  PayeeContactCreate,
  PayeeContactPatch,
  GetPayeeContactArchiveRestrictionsList200,
  GetPayeeContactArchiveRestrictionsListParams,
  ListPayerContactsParams,
  ListPayerContacts200,
  PayerContact,
  PayerContactCreate,
  PayerContactPatch,
  GetPayerContactArchiveRestrictionsList200,
  GetPayerContactArchiveRestrictionsListParams,
  ListExternalCommunicationConfigsParams,
  ListExternalCommunicationConfigs200,
  ExternalCommunicationConfig,
  ExternalCommunicationConfigCreate,
  ExternalCommunicationConfigPatch,
  ListStripeConnectedAccountLinksParams,
  ListStripeConnectedAccountLinks200,
  StripeConnectedAccountLink,
  ListBusinessLogoEmbeddedFilesParams,
  ListBusinessLogoEmbeddedFiles200,
  BusinessLogoEmbeddedFile,
  BusinessLogoEmbeddedFileCreate,
  BusinessLogoEmbeddedFilePatch,
  ListChangeOrderEmbeddedFilesParams,
  ListChangeOrderEmbeddedFiles200,
  EmbeddedFile,
  EmbeddedFileCreate,
  ListChangeOrdersParams,
  ListChangeOrders200,
  ChangeOrder,
  ChangeOrderCreate,
  ChangeOrderPatch,
  ListClientsParams,
  ListClients200,
  Client,
  ListProjectPermissionsParams,
  ListProjectPermissions200,
  ProjectPermission,
  ListProjectsParams,
  ListProjects200,
  Project,
  ProjectCreate,
  ProjectPatch,
  ListRemovalChangeOrderEmbeddedFilesParams,
  ListRemovalChangeOrderEmbeddedFiles200,
  ListRemovalChangeOrdersParams,
  ListRemovalChangeOrders200,
  RemovalChangeOrder,
  RemovalChangeOrderPatch,
  ContractItemEditRestrictionViewSetOutput,
  ContractItemEditRestrictionViewSetParams,
  ListContractEmbeddedFile2sParams,
  ListContractEmbeddedFile2s200,
  ContractEmbeddedFile2,
  ContractEmbeddedFile2Create,
  ContractEmbeddedFile2Patch,
  ListContractItemsParams,
  ListContractItems200,
  ContractItem,
  ArchiveRestriction,
  ContractItemCreate,
  ContractItemPatch,
  ListContractsParams,
  ListContracts200,
  Contract,
  ContractCreate,
  ContractPatch,
  ListCostCodesParams,
  ListCostCodes200,
  CostCode,
  CostCodeCreate,
  CostCodePatch,
  ListCostTypesParams,
  ListCostTypes200,
  CostType,
  CostTypeCreate,
  CostTypePatch,
  ListGeneralProjectConfigsParams,
  ListGeneralProjectConfigs200,
  GeneralProjectConfig,
  GeneralProjectConfigCreate,
  GeneralProjectConfigPatch,
  ListRetainageReleasesParams,
  ListRetainageReleases200,
  RetainageRelease,
  RetainageReleaseCreate,
  ListInternalNoteFileLinksParams,
  ListInternalNoteFileLinks200,
  InternalNoteFileLink,
  InternalNoteFileLinkCreate,
  ListInternalNotesParams,
  ListInternalNotes200,
  InternalNote,
  InternalNoteCreate,
  InternalNotePatch,
  ListNoteFileLinksParams,
  ListNoteFileLinks200,
  NoteFileLink,
  NoteFileLinkCreate,
  ListNoteMentionLinksParams,
  ListNoteMentionLinks200,
  NoteMentionLink,
  NoteMentionLinkCreate,
  ListNotesParams,
  ListNotes200,
  Note,
  NoteCreate,
  ListBaseInvoiceEmbeddedFilesParams,
  ListBaseInvoiceEmbeddedFiles200,
  ListBaseInvoiceEmbeddedPrimaryFilesParams,
  ListBaseInvoiceEmbeddedPrimaryFiles200,
  ListBillEmbeddedFilesParams,
  ListBillEmbeddedFiles200,
  ListBillEmbeddedPrimaryFilesParams,
  ListBillEmbeddedPrimaryFiles200,
  ListBillsParams,
  ListBills200,
  Bill,
  BillCreate,
  BillPatch,
  ListContractProgressSummarysParams,
  ListContractProgressSummarys200,
  ContractProgressSummary,
  ListExternalPaymentSourcesParams,
  ListExternalPaymentSources200,
  ExternalPaymentSource,
  ListFreeFormInvoiceLineItemsParams,
  ListFreeFormInvoiceLineItems200,
  FreeFormInvoiceLineItem,
  FreeFormInvoiceLineItemPatch,
  ListInvoiceActionRequestsParams,
  ListInvoiceActionRequests200,
  InvoiceActionRequest,
  InvoiceActionRequestCreate,
  ListInvoiceApprovalActionsParams,
  ListInvoiceApprovalActions200,
  InvoiceApprovalAction,
  InvoiceApprovalActionCreate,
  ListInvoiceConfigsParams,
  ListInvoiceConfigs200,
  InvoiceConfig,
  ListInvoiceEmbeddedFilesParams,
  ListInvoiceEmbeddedFiles200,
  ListInvoiceEmbeddedPrimaryFilesParams,
  ListInvoiceEmbeddedPrimaryFiles200,
  ListInvoiceLineItemsParams,
  ListInvoiceLineItems200,
  InvoiceLineItem,
  InvoiceLineItemCreate,
  InvoiceLineItemPatch,
  ListInvoicePaymentGroupsParams,
  ListInvoicePaymentGroups200,
  InvoicePaymentGroup,
  InvoicePaymentGroupCreate,
  InvoicePaymentGroupPatch,
  ListInvoicePaymentsParams,
  ListInvoicePayments200,
  InvoicePayment,
  InvoicePaymentCreate,
  InvoicePaymentPatch,
  GetInvoicePaymentArchiveRestrictionsList200,
  GetInvoicePaymentArchiveRestrictionsListParams,
  ListInvoicePermissionsParams,
  ListInvoicePermissions200,
  InvoicePermission,
  ListInvoicesParams,
  ListInvoices200,
  Invoice,
  InvoiceCreate,
  InvoicePatch,
  ListLienWaiverClaimantSignaturesParams,
  ListLienWaiverClaimantSignatures200,
  LienWaiverClaimantSignature,
  ListLienWaiverEmbeddedFilesParams,
  ListLienWaiverEmbeddedFiles200,
  ListLienWaiverEmbeddedPrimaryFilesParams,
  ListLienWaiverEmbeddedPrimaryFiles200,
  ListLienWaiversParams,
  ListLienWaivers200,
  LienWaiver,
  LienWaiverCreate,
  LienWaiverPatch,
  ListPrimeContractInvoiceEmailsParams,
  ListPrimeContractInvoiceEmails200,
  PrimeContractInvoiceEmail,
  ListPrimeContractInvoiceEmailViewsParams,
  ListPrimeContractInvoiceEmailViews200,
  PrimeContractInvoiceEmailView,
  ListPrimeContractInvoicePaymentPageVisitsParams,
  ListPrimeContractInvoicePaymentPageVisits200,
  PrimeContractInvoicePaymentPageVisit,
  PrimeContractInvoicePaymentPageVisitCreate,
  ListProjectExpensePermissionsParams,
  ListProjectExpensePermissions200,
  ProjectExpensePermission,
  ListProjectExpenseReceiptEmbeddedFilesParams,
  ListProjectExpenseReceiptEmbeddedFiles200,
  ListProjectExpensesParams,
  ListProjectExpenses200,
  ProjectExpense,
  ProjectExpenseCreate,
  ProjectExpensePatch,
  ListProjectProgressSummaryV2sParams,
  ListProjectProgressSummaryV2s200,
  ProjectProgressSummaryV2,
  ListScheduleOfValuesItemProgressSummarysParams,
  ListScheduleOfValuesItemProgressSummarys200,
  ScheduleOfValuesItemProgressSummary,
  GetExpenseMetricsOutput,
  GetInvoiceApprovalWorkflowRequirementsViewOutput,
  GetInvoiceApprovalWorkflowRequirementsParams,
  GetInvoiceCardFeeOutput,
  GetInvoiceCardFeeParams,
  GetInvoiceFileParams,
  GetInvoicePaymentStartDateViewOutput,
  GetInvoicePaymentStartDateParams,
  GetLienWaiverPaymentBlockersOutput,
  GetLienWaiverPaymentBlockersParams,
  GetLienWaiverPdfPreviewV2Params,
  GetNextInvoiceNumberOutput,
  GetRetainageValuesViewOutput,
  GetRetainageValuesParams,
  PredictUncategorizedExpenseDetailsOutput,
  PredictUncategorizedExpenseDetailsParams,
  ListInvoiceApprovalRulesParams,
  ListInvoiceApprovalRules200,
  InvoiceApprovalRule,
  InvoiceApprovalRuleCreate,
  InvoiceApprovalRulePatch,
  ListInvoiceIntentEmbeddedFilesParams,
  ListInvoiceIntentEmbeddedFiles200,
  InvoiceIntentEmbeddedFile,
  InvoiceIntentEmbeddedFileCreate,
  InvoiceIntentEmbeddedFilePatch,
  ListInvoiceIntentsParams,
  ListInvoiceIntents200,
  InvoiceIntent,
  InvoiceIntentCreate,
  InvoiceIntentPatch,
  ListInvoiceLineItemMarkupLinksParams,
  ListInvoiceLineItemMarkupLinks200,
  InvoiceLineItemMarkupLink,
  InvoiceLineItemMarkupLinkCreate,
  InvoiceLineItemMarkupLinkPatch,
  ListInvoicePrefillLineItemsParams,
  ListInvoicePrefillLineItems200,
  InvoicePrefillLineItem,
  ListInvoicePrefillsParams,
  ListInvoicePrefills200,
  InvoicePrefill,
  ListPaymentSourcesParams,
  ListPaymentSources200,
  PaymentSource,
  PaymentSourceCreate,
  PaymentSourcePatch,
  ListTimeEntrysParams,
  ListTimeEntrys200,
  TimeEntry,
  TimeEntryCreate,
  TimeEntryPatch,
  ListVendorBillingConfigsParams,
  ListVendorBillingConfigs200,
  VendorBillingConfig,
  VendorBillingConfigCreate,
  VendorBillingConfigPatch,
  ListWorkCompletedSourcesParams,
  ListWorkCompletedSources200,
  WorkCompletedSource,
  WorkCompletedSourceCreate,
  WorkCompletedSourcePatch,
  ListDummysParams,
  ListDummys200,
  ListEmailVerificationAttemptsParams,
  ListEmailVerificationAttempts200,
  EmailVerificationAttempt,
  EmailVerificationAttemptCreate,
  ListEmailVerificationsParams,
  ListEmailVerifications200,
  EmailVerification,
  EmailVerificationCreate,
  ListUsersParams,
  ListUsers200,
  User,
  UserCreate,
  UserPatch,
  ListCardsParams,
  ListCards200,
  Card,
  ListMicrodepositRequirementsParams,
  ListMicrodepositRequirements200,
  MicrodepositRequirement,
  ListTransactionAccountsParams,
  ListTransactionAccounts200,
  TransactionAccount,
  GetBankNameViewOutput,
  GetBankNameParams,
  GetStatementPDFParams,
  ListBankAccountsParams,
  ListBankAccounts200,
  BankAccount,
  BankAccountCreate,
  BankAccountPatch,
  ListControlledPayeeBankAccountsParams,
  ListControlledPayeeBankAccounts200,
  ControlledPayeeBankAccount,
  ControlledPayeeBankAccountCreate,
  ControlledPayeeBankAccountPatch,
  ListPayeeBankAccountsParams,
  ListPayeeBankAccounts200,
  PayeeBankAccount,
  ListCashBackPaymentsParams,
  ListCashBackPayments200,
  CashBackPayment,
  ListYieldPaymentsParams,
  ListYieldPayments200,
  YieldPayment,
  GetProjectBudgetCostViewOutput,
  GetProjectBudgetCostParams,
  ListBudgetConfigsParams,
  ListBudgetConfigs200,
  BudgetConfig,
  BudgetConfigCreate,
  BudgetConfigPatch,
  ListProjectBudgetItemsParams,
  ListProjectBudgetItems200,
  ProjectBudgetItem,
  ProjectBudgetItemCreate,
  ProjectBudgetItemPatch,
  ListProjectBudgetsParams,
  ListProjectBudgets200,
  ProjectBudget,
  ProjectBudgetCreate,
  ProjectBudgetPatch,
  ListTasksParams,
  ListTasks200,
  Task,
  FileCreate,
  ListBusinessProfilesParams,
  ListBusinessProfiles200,
  BusinessProfile,
  BusinessProfileCreate,
  OnboardingFlow,
  WhoAmIViewOutput,
  ListEmployeeProfilesParams,
  ListEmployeeProfiles200,
  EmployeeProfile,
  EmployeeProfileCreate,
  EmployeeProfilePatch,
  ListOnboardingSurveysParams,
  ListOnboardingSurveys200,
  OnboardingSurvey,
  OnboardingSurveyCreate,
  OnboardingSurveyPatch,
  ListTermsOfServiceAcceptancesParams,
  ListTermsOfServiceAcceptances200,
  TermsOfServiceAcceptance,
  TermsOfServiceAcceptanceCreate,
  ListTermsOfServicesParams,
  ListTermsOfServices200,
  TermsOfService,
  ListComplianceDocumentEmbeddedFilesParams,
  ListComplianceDocumentEmbeddedFiles200,
  ListComplianceDocumentsParams,
  ListComplianceDocuments200,
  ComplianceDocument,
  ComplianceDocumentCreate,
  ListComplianceSettingsListsParams,
  ListComplianceSettingsLists200,
  ComplianceSettingsList,
  ComplianceSettingsListPatch,
  ListComplianceStatusSummarysParams,
  ListComplianceStatusSummarys200,
  ComplianceStatusSummary,
  ListPayeeComplianceRequirementsParams,
  ListPayeeComplianceRequirements200,
  PayeeComplianceRequirement,
  PayeeComplianceRequirementPatch,
  ListRequirementListsParams,
  ListRequirementLists200,
  RequirementList,
  GetCombinedComplianceSettingsListAsPayeeViewOutput,
  GetCombinedComplianceSettingsListAsPayeeParams,
  GetComplianceSettingsListForPayeeViewOutput,
  GetComplianceSettingsListForPayeeParams,
  ListComplianceSettingsListPayeeOverridesParams,
  ListComplianceSettingsListPayeeOverrides200,
  ComplianceSettingsListPayeeOverride,
  ComplianceSettingsListPayeeOverrideCreate,
  ComplianceSettingsListPayeeOverridePatch,
  ListEstimateApprovalSignaturesParams,
  ListEstimateApprovalSignatures200,
  EstimateApprovalSignature,
  ListEstimateEmailsParams,
  ListEstimateEmails200,
  EstimateEmail,
  ListEstimateEmailViewsParams,
  ListEstimateEmailViews200,
  EstimateEmailView,
  ListEstimateEmbeddedFilesParams,
  ListEstimateEmbeddedFiles200,
  ListEstimatePageVisitsParams,
  ListEstimatePageVisits200,
  EstimatePaymentPageVisit,
  EstimatePaymentPageVisitCreate,
  ListEstimatesParams,
  ListEstimates200,
  Estimate,
  EstimateCreate,
  EstimatePatch,
  GetEstimateCoverPagePdfPreviewParams,
  GetEstimateInputFromFileViewOutput,
  GetEstimateInputFromFileParams,
  GetNextEstimateNumberOutput,
  GetNextEstimateNumberParams,
  ListEstimateConfigsParams,
  ListEstimateConfigs200,
  EstimateConfig,
  EstimateConfigCreate,
  EstimateConfigPatch,
  ListEstimateCoverPagePhotosParams,
  ListEstimateCoverPagePhotos200,
  EstimateCoverPagePhoto,
  EstimateCoverPagePhotoCreate,
  ListEstimateCoverPagesParams,
  ListEstimateCoverPages200,
  EstimateCoverPage,
  EstimateCoverPageCreate,
  EstimateCoverPagePatch,
  ListEstimatesAsPayeePageSummarysParams,
  ListEstimatesAsPayeePageSummarys200,
  EstimatesAsPayeePageSummary,
  ListAccountsParams,
  ListAccounts200,
  Account,
  AccountCreate,
  AccountPatch,
  ListInvoiceOutboundSyncV2sParams,
  ListInvoiceOutboundSyncV2s200,
  InvoiceOutboundSyncV2,
  GetLinkTokenOutput,
  GetProjectImportRequestViewOutput,
  GetUpdatedTokenStatusOutput,
  ListQBPullRequestsParams,
  ListQBPullRequests200,
  QBPullRequest,
  ListQBPushRequestsParams,
  ListQBPushRequests200,
  QBPushRequest,
  ListQboLinksParams,
  ListQboLinks200,
  QboLink,
  ListRawQboLinksParams,
  ListRawQboLinks200,
  RawQboLink,
  ListBillListRowsParams,
  ListBillListRows200,
  BillListRow,
  ListCardTransactionListRowsParams,
  ListCardTransactionListRows200,
  CardTransactionListRow,
  ListClientListRowsParams,
  ListClientListRows200,
  ClientListRow,
  ListExpenseListRowsParams,
  ListExpenseListRows200,
  ExpenseListRow,
  ListGCSubcontractInlineListRowsParams,
  ListGCSubcontractInlineListRows200,
  GCSubcontractInlineListRow,
  ListInvoiceListRowsParams,
  ListInvoiceListRows200,
  InvoiceListRow,
  ListInvoicePaymentFeeListRowsParams,
  ListInvoicePaymentFeeListRows200,
  InvoicePaymentFeeListRow,
  ListProjectListRowsParams,
  ListProjectListRows200,
  ProjectListRow,
  ListVendorListRowsParams,
  ListVendorListRows200,
  VendorListRow,
  ListBillsPageSummarysParams,
  ListBillsPageSummarys200,
  BillsPageSummary,
  ListEstimatesPageSummarysParams,
  ListEstimatesPageSummarys200,
  EstimatesPageSummary,
  ListInvoicesPageSummarysParams,
  ListInvoicesPageSummarys200,
  InvoicesPageSummary,
  ListProjectsPageSummarysParams,
  ListProjectsPageSummarys200,
  ProjectsPageSummary,
  APIActivityViewOutput,
  APIActivityParams,
  AggregatedPaymentListRowViewOutput,
  AggregatedPaymentListRowParams,
  CustomerSummaryViewOutput,
  CustomerSummaryParams,
  GetReadOnlyAuthCommandViewOutput,
  GetReadOnlyAuthCommandParams,
  GetAllowedQBBucketSearchesViewOutput,
  GetAllowedQBBucketSearchesParams,
  ListAdminQBPullRequestsParams,
  ListAdminQBPullRequests200,
  AdminQBPullRequest,
  ListAdminQBPushRequestsParams,
  ListAdminQBPushRequests200,
  AdminQBPushRequest,
  ListAdminTransactionsParams,
  ListAdminTransactions200,
  AdminTransaction,
  ListAgaveAccountsParams,
  ListAgaveAccounts200,
  AgaveAccount,
  ListBusinessNotesParams,
  ListBusinessNotes200,
  BusinessNote,
  BusinessNoteCreate,
  BusinessNotePatch,
  EmailPixelViewOutput,
  ListBeamBalanceTransactionsParams,
  ListBeamBalanceTransactions200,
  BeamBalanceTransaction,
  ListTransfersParams,
  ListTransfers200,
  Transfer,
  TransferCreate,
  GetHubspotVisitorTokenViewOutput,
  ListCardTransactionsParams,
  ListCardTransactions200,
  CardTransaction,
  ListExpenseCardsParams,
  ListExpenseCards200,
  ExpenseCard,
  ListCardProgramsParams,
  ListCardPrograms200,
  CardProgram,
  GetAccountLoginURLViewOutput,
  GetInvoiceCtaViewOutput,
  GetInvoiceCtaParams,
  GetInvoiceListCtaViewOutput,
  GetInvoiceListCtaParams,
  ListExpenseCodesParams,
  ListExpenseCodes200,
  ExpenseCode,
  ListIncomeCodesParams,
  ListIncomeCodes200,
  IncomeCode,
  ListItemsParams,
  ListItems200,
  Item,
  ListLedgerAccountsParams,
  ListLedgerAccounts200,
  LedgerAccount,
  ListBillOcrRawResultLineItemsParams,
  ListBillOcrRawResultLineItems200,
  BillOcrRawResultLineItem,
  ListBillOcrRawResultsParams,
  ListBillOcrRawResults200,
  BillOcrRawResult,
  ListMfaRequirementsParams,
  ListMfaRequirements200,
  MfaRequirement,
  GetAuthRequestLinkViewOutput,
  GetAuthRequestLinkParams,
  ListCompanyCamAccountsParams,
  ListCompanyCamAccounts200,
  CompanyCamAccount,
  CompanyCamAccountCreate,
  ListProcoreAccountsParams,
  ListProcoreAccounts200,
  ProcoreAccount,
  ProcoreAccountCreate,
  ListQboAccountsParams,
  ListQboAccounts200,
  QboAccount,
  QboAccountCreate,
  ListCompanyCamLinksParams,
  ListCompanyCamLinks200,
  CompanyCamLink,
  ListDocumentsParams,
  ListDocuments200,
  Document,
  ListPhotosParams,
  ListPhotos200,
  Photo,
  ListRenofiAdsParams,
  ListRenofiAds200,
  RenofiAd,
  RenofiAdCreate,
  RenofiAdPatch,
  GetRenofiAdViewOutput,
  GetRenofiAdParams,
  ListRenofiAdClicksParams,
  ListRenofiAdClicks200,
  RenofiAdClick,
  SetBusinessStatusInput,
  BumpInvoiceVersionInput,
  SendInvoiceEmailInput,
  SendLienWaiverEmailInput,
  LoginCreate,
  LogoutV2Create,
  AuthenticateMagicLinkViewInput,
  GenerateMagicLinkTokenViewInput,
  PasswordResetCompletionViewInput,
  PasswordResetInitializationViewInput,
  VerifyEmailViewInput,
  MicrodepositAttemptCreate,
  SetupIntentCreate,
  SetupIntentPatch,
  SetInboundTransfersEnabledViewInput,
  SetMfaRequirementInput,
  FileUploadCreate,
  GenerateStripeOnboardingClientSecretViewInput,
  SetOnboardingPushedToStripeViewInput,
  SetPayerEnabledViewInput,
  SendClientInvoiceReceivedEmailViewSetInput,
  PublishEstimateInput,
  SendEstimateReminderInput,
  ArchiveBankAccountViewInput,
  GetQBBucketsViewInput,
  TrackEmailLinkClickedViewInput,
  CategorizeTransactionInput,
  InitiateBillOcrInput,
  MfaAttemptCreate,
  ESignatureCreate,
  UpdateAuthTokenViewInput,
  ClickLinkViewInput,
  UpdateMoneyMovementViewInput,
} from './models'

export class PublicLogoSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/logos/`

  // plugin buildResourceMethods
  static image = G.publicLogo

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/businesses/logos/']

  static imageQ = <TSelect = PublicLogoViewOutput>(
    id: string,
    props?: Utils.UseQueryOptions<PublicLogoViewOutput, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.image(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'image']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class AdditionalContactSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/additional_contacts/`

  // plugin buildResourceMethods
  static list = G.listAdditionalContacts

  static create = G.createAdditionalContact

  static retrieve = G.retrieveAdditionalContact

  static update = G.partialUpdateAdditionalContact

  static delete = G.destroyAdditionalContact

  // plugin addResourceDependencies
  static downstream = ['/businesses/additional_contacts/']

  // plugin addQuerySpecs
  static key = () => ['/businesses/additional_contacts/']

  static listQ = <TSelect = ListAdditionalContacts200['results']>(
    props?: Utils.UseQueryOptions<ListAdditionalContacts200, unknown, TSelect> &
      Utils.Params<ListAdditionalContactsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = AdditionalContact>(
    id: string | null,
    props?: Utils.UseQueryOptions<AdditionalContact, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: AdditionalContactCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<AdditionalContactPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BusinessSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/`

  // plugin buildResourceMethods
  static list = G.listBusiness

  static create = G.createBusiness

  static retrieve = G.retrieveBusiness

  static update = G.partialUpdateBusiness

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/accounting/income_codes/',
    '/accounting/items/',
    '/accounting/ledger_accounts/',
    '/admin/accounts/',
    '/admin/business_notes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/payment_sources/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/budget_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/external_communication_configs/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/businesses/stripe_connected_account_links/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_configs/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_programs/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
    '/ocr/bill_ocr_raw_result_line_items/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/onboarding/onboarding_surveys/',
    '/onboarding/terms_of_service_acceptances/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
    '/projects/cost_types/',
    '/projects/general_project_configs/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
    '/users/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/businesses/']

  static listQ = <TSelect = ListBusiness200['results']>(
    props?: Utils.UseQueryOptions<ListBusiness200, unknown, TSelect> &
      Utils.Params<ListBusinessParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Business>(
    id: string | null,
    props?: Utils.UseQueryOptions<Business, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BusinessCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<BusinessPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EmployeeCommunicationConfigSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/employee_communication_configs/`

  // plugin buildResourceMethods
  static list = G.listEmployeeCommunicationConfigs

  static singleton = G.retrieveSingletonEmployeeCommunicationConfig

  static retrieve = G.retrieveEmployeeCommunicationConfig

  static update = G.partialUpdateEmployeeCommunicationConfig

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/businesses/employee_communication_configs/']

  static listQ = <TSelect = ListEmployeeCommunicationConfigs200['results']>(
    props?: Utils.UseQueryOptions<ListEmployeeCommunicationConfigs200, unknown, TSelect> &
      Utils.Params<ListEmployeeCommunicationConfigsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = EmployeeCommunicationConfig>(
    props?: Utils.UseQueryOptions<EmployeeCommunicationConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  static retrieveQ = <TSelect = EmployeeCommunicationConfig>(
    id: string | null,
    props?: Utils.UseQueryOptions<EmployeeCommunicationConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<EmployeeCommunicationConfigPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EmployeeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/employees/`

  // plugin buildResourceMethods
  static list = G.listEmployees

  static create = G.createEmployee

  static retrieve = G.retrieveEmployee

  static update = G.partialUpdateEmployee

  static delete = G.destroyEmployee

  // plugin addResourceDependencies
  static downstream = [
    '/admin/business_notes/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/cards/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/budgeting/get_project_budget_cost/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/get_auth_request_link/',
    '/onboarding/business_profiles/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
    '/users/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/businesses/employees/']

  static listQ = <TSelect = ListEmployees200['results']>(
    props?: Utils.UseQueryOptions<ListEmployees200, unknown, TSelect> &
      Utils.Params<ListEmployeesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Employee>(
    id: string | null,
    props?: Utils.UseQueryOptions<Employee, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmployeeCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<EmployeePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GCEmailSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/gc_emails/`

  // plugin buildResourceMethods
  static list = G.listGCEmails

  static create = G.createGCEmail

  static retrieve = G.retrieveGCEmail

  // plugin addResourceDependencies
  static downstream = ['/businesses/gc_emails/']

  // plugin addQuerySpecs
  static key = () => ['/businesses/gc_emails/']

  static listQ = <TSelect = ListGCEmails200['results']>(
    props?: Utils.UseQueryOptions<ListGCEmails200, unknown, TSelect> &
      Utils.Params<ListGCEmailsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = GCEmail>(
    id: string | null,
    props?: Utils.UseQueryOptions<GCEmail, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: GCEmailCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class OutboundInviteSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/outbound_invites/`

  // plugin buildResourceMethods
  static list = G.listOutboundInvites

  static create = G.createOutboundInvite

  static retrieve = G.retrieveOutboundInvite

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/accounting/income_codes/',
    '/accounting/items/',
    '/accounting/ledger_accounts/',
    '/admin/accounts/',
    '/admin/business_notes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/payment_sources/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/budget_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/external_communication_configs/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/businesses/stripe_connected_account_links/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_configs/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_programs/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
    '/ocr/bill_ocr_raw_result_line_items/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/onboarding/onboarding_surveys/',
    '/onboarding/terms_of_service_acceptances/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
    '/projects/cost_types/',
    '/projects/general_project_configs/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/businesses/outbound_invites/']

  static listQ = <TSelect = ListOutboundInvites200['results']>(
    props?: Utils.UseQueryOptions<ListOutboundInvites200, unknown, TSelect> &
      Utils.Params<ListOutboundInvitesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = OutboundInvite>(
    id: string | null,
    props?: Utils.UseQueryOptions<OutboundInvite, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: OutboundInviteCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class PayeeContactSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/payee_contacts/`

  // plugin buildResourceMethods
  static list = G.listPayeeContacts

  static create = G.createPayeeContact

  static retrieve = G.retrievePayeeContact

  static update = G.partialUpdatePayeeContact

  static archive_restrictions = G.getPayeeContactArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/accounting/income_codes/',
    '/accounting/items/',
    '/accounting/ledger_accounts/',
    '/admin/accounts/',
    '/admin/business_notes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/payment_sources/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/budget_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/external_communication_configs/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/businesses/stripe_connected_account_links/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_configs/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_programs/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
    '/ocr/bill_ocr_raw_result_line_items/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/onboarding/onboarding_surveys/',
    '/onboarding/terms_of_service_acceptances/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
    '/projects/cost_types/',
    '/projects/general_project_configs/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/businesses/payee_contacts/']

  static listQ = <TSelect = ListPayeeContacts200['results']>(
    props?: Utils.UseQueryOptions<ListPayeeContacts200, unknown, TSelect> &
      Utils.Params<ListPayeeContactsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PayeeContact>(
    id: string | null,
    props?: Utils.UseQueryOptions<PayeeContact, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = GetArchiveRestrictionsOutput>(
    id: string,
    props?: Utils.UseQueryOptions<GetArchiveRestrictionsOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: PayeeContactCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<PayeeContactPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GetPayeeContactArchiveRestrictionsListSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/payee_contacts/archive_restrictions/`

  // plugin buildResourceMethods
  static get = G.getPayeeContactArchiveRestrictionsList

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/businesses/payee_contacts/archive_restrictions/']

  static getQ = <TSelect = GetPayeeContactArchiveRestrictionsList200>(
    props: Utils.UseQueryOptions<GetPayeeContactArchiveRestrictionsList200, unknown, TSelect> & {
      params: GetPayeeContactArchiveRestrictionsListParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class PayerContactSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/payer_contacts/`

  // plugin buildResourceMethods
  static list = G.listPayerContacts

  static create = G.createPayerContact

  static retrieve = G.retrievePayerContact

  static update = G.partialUpdatePayerContact

  static archive_restrictions = G.getPayerContactArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/accounting/income_codes/',
    '/accounting/items/',
    '/accounting/ledger_accounts/',
    '/admin/accounts/',
    '/admin/business_notes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/payment_sources/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/budget_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/external_communication_configs/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/businesses/stripe_connected_account_links/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_configs/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_programs/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
    '/ocr/bill_ocr_raw_result_line_items/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/onboarding/onboarding_surveys/',
    '/onboarding/terms_of_service_acceptances/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
    '/projects/cost_types/',
    '/projects/general_project_configs/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
    '/users/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/businesses/payer_contacts/']

  static listQ = <TSelect = ListPayerContacts200['results']>(
    props?: Utils.UseQueryOptions<ListPayerContacts200, unknown, TSelect> &
      Utils.Params<ListPayerContactsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PayerContact>(
    id: string | null,
    props?: Utils.UseQueryOptions<PayerContact, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = GetArchiveRestrictionsOutput>(
    id: string,
    props?: Utils.UseQueryOptions<GetArchiveRestrictionsOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: PayerContactCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<PayerContactPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GetPayerContactArchiveRestrictionsListSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/payer_contacts/archive_restrictions/`

  // plugin buildResourceMethods
  static get = G.getPayerContactArchiveRestrictionsList

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/businesses/payer_contacts/archive_restrictions/']

  static getQ = <TSelect = GetPayerContactArchiveRestrictionsList200>(
    props: Utils.UseQueryOptions<GetPayerContactArchiveRestrictionsList200, unknown, TSelect> & {
      params: GetPayerContactArchiveRestrictionsListParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class ExternalCommunicationConfigSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/external_communication_configs/`

  // plugin buildResourceMethods
  static list = G.listExternalCommunicationConfigs

  static create = G.createExternalCommunicationConfig

  static retrieve = G.retrieveExternalCommunicationConfig

  static update = G.partialUpdateExternalCommunicationConfig

  static singleton = G.retrieveSingletonExternalCommunicationConfig

  // plugin addResourceDependencies
  static downstream = ['/businesses/external_communication_configs/']

  // plugin addQuerySpecs
  static key = () => ['/businesses/external_communication_configs/']

  static listQ = <TSelect = ListExternalCommunicationConfigs200['results']>(
    props?: Utils.UseQueryOptions<ListExternalCommunicationConfigs200, unknown, TSelect> &
      Utils.Params<ListExternalCommunicationConfigsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ExternalCommunicationConfig>(
    id: string | null,
    props?: Utils.UseQueryOptions<ExternalCommunicationConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = ExternalCommunicationConfig>(
    props?: Utils.UseQueryOptions<ExternalCommunicationConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ExternalCommunicationConfigCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ExternalCommunicationConfigPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class StripeConnectedAccountLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/stripe_connected_account_links/`

  // plugin buildResourceMethods
  static list = G.listStripeConnectedAccountLinks

  static retrieve = G.retrieveStripeConnectedAccountLink

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/businesses/stripe_connected_account_links/']

  static listQ = <TSelect = ListStripeConnectedAccountLinks200['results']>(
    props?: Utils.UseQueryOptions<ListStripeConnectedAccountLinks200, unknown, TSelect> &
      Utils.Params<ListStripeConnectedAccountLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = StripeConnectedAccountLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<StripeConnectedAccountLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class BusinessLogoEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/logos/`

  // plugin buildResourceMethods
  static list = G.listBusinessLogoEmbeddedFiles

  static create = G.createBusinessLogoEmbeddedFile

  static retrieve = G.retrieveBusinessLogoEmbeddedFile

  static update = G.partialUpdateBusinessLogoEmbeddedFile

  static delete = G.destroyBusinessLogoEmbeddedFile

  static download = G.retrieveBusinessLogoEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = [
    '/billing/get_invoice_file/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/projects/logos/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/logos/']

  static listQ = <TSelect = ListBusinessLogoEmbeddedFiles200['results']>(
    props?: Utils.UseQueryOptions<ListBusinessLogoEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListBusinessLogoEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BusinessLogoEmbeddedFile>(
    id: string | null,
    props?: Utils.UseQueryOptions<BusinessLogoEmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BusinessLogoEmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<BusinessLogoEmbeddedFilePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ChangeOrderEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (changeOrderId: string) => `/projects/change_orders/${changeOrderId}/files/`

  // plugin buildResourceMethods
  static list = G.listChangeOrderEmbeddedFiles

  static create = G.createChangeOrderEmbeddedFile

  static retrieve = G.retrieveChangeOrderEmbeddedFile

  static download = G.retrieveChangeOrderEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (changeOrderId: string) => [
    '/projects/change_orders/',
    `${changeOrderId}`,
    '/files/',
  ]

  static listQ = <TSelect = ListChangeOrderEmbeddedFiles200['results']>(
    changeOrderId: string,
    props?: Utils.UseQueryOptions<ListChangeOrderEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListChangeOrderEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(changeOrderId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(changeOrderId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    changeOrderId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(changeOrderId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(changeOrderId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    changeOrderId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(changeOrderId, id)
    const queryKey: Utils.QueryKey = [...this.key(changeOrderId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [changeOrderId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ChangeOrderSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/change_orders/`

  // plugin buildResourceMethods
  static list = G.listChangeOrders

  static create = G.createChangeOrder

  static retrieve = G.retrieveChangeOrder

  static update = G.partialUpdateChangeOrder

  static delete = G.destroyChangeOrder

  // plugin addResourceDependencies
  static downstream = [
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/get_retainage_values/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/budgeting/get_project_budget_cost/',
    '/estimates/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/get_next_estimate_number/',
    '/page_resources/bill_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/change_orders/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/retainage_releases/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/change_orders/']

  static listQ = <TSelect = ListChangeOrders200['results']>(
    props?: Utils.UseQueryOptions<ListChangeOrders200, unknown, TSelect> &
      Utils.Params<ListChangeOrdersParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ChangeOrder>(
    id: string | null,
    props?: Utils.UseQueryOptions<ChangeOrder, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ChangeOrderCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ChangeOrderPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ClientSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/clients/`

  // plugin buildResourceMethods
  static list = G.listClients

  static retrieve = G.retrieveClient

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/projects/clients/']

  static listQ = <TSelect = ListClients200['results']>(
    props?: Utils.UseQueryOptions<ListClients200, unknown, TSelect> &
      Utils.Params<ListClientsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Client>(
    id: string | null,
    props?: Utils.UseQueryOptions<Client, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ProjectPermissionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/project_permissions/`

  // plugin buildResourceMethods
  static list = G.listProjectPermissions

  static retrieve = G.retrieveProjectPermission

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/projects/project_permissions/']

  static listQ = <TSelect = ListProjectPermissions200['results']>(
    props?: Utils.UseQueryOptions<ListProjectPermissions200, unknown, TSelect> &
      Utils.Params<ListProjectPermissionsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectPermission>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectPermission, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ProjectSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/`

  // plugin buildResourceMethods
  static list = G.listProjects

  static create = G.createProject

  static retrieve = G.retrieveProject

  static update = G.partialUpdateProject

  static delete = G.destroyProject

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/banking/get_statement_pdf/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_status_summarys/',
    '/estimates/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/']

  static listQ = <TSelect = ListProjects200['results']>(
    props?: Utils.UseQueryOptions<ListProjects200, unknown, TSelect> &
      Utils.Params<ListProjectsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Project>(
    id: string | null,
    props?: Utils.UseQueryOptions<Project, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ProjectCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ProjectPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class RemovalChangeOrderEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (removalChangeOrderId: string) =>
    `/projects/removal_change_orders/${removalChangeOrderId}/files/`

  // plugin buildResourceMethods
  static list = G.listRemovalChangeOrderEmbeddedFiles

  static create = G.createRemovalChangeOrderEmbeddedFile

  static retrieve = G.retrieveRemovalChangeOrderEmbeddedFile

  static download = G.retrieveRemovalChangeOrderEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (removalChangeOrderId: string) => [
    '/projects/removal_change_orders/',
    `${removalChangeOrderId}`,
    '/files/',
  ]

  static listQ = <TSelect = ListRemovalChangeOrderEmbeddedFiles200['results']>(
    removalChangeOrderId: string,
    props?: Utils.UseQueryOptions<ListRemovalChangeOrderEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListRemovalChangeOrderEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(removalChangeOrderId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(removalChangeOrderId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    removalChangeOrderId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(removalChangeOrderId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(removalChangeOrderId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    removalChangeOrderId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(removalChangeOrderId, id)
    const queryKey: Utils.QueryKey = [...this.key(removalChangeOrderId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [removalChangeOrderId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class RemovalChangeOrderSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/removal_change_orders/`

  // plugin buildResourceMethods
  static list = G.listRemovalChangeOrders

  static retrieve = G.retrieveRemovalChangeOrder

  static update = G.partialUpdateRemovalChangeOrder

  static delete = G.destroyRemovalChangeOrder

  // plugin addResourceDependencies
  static downstream = [
    '/billing/contract_progress_summarys/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/budgeting/get_project_budget_cost/',
    '/page_resources/client_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/removal_change_orders/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/removal_change_orders/']

  static listQ = <TSelect = ListRemovalChangeOrders200['results']>(
    props?: Utils.UseQueryOptions<ListRemovalChangeOrders200, unknown, TSelect> &
      Utils.Params<ListRemovalChangeOrdersParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = RemovalChangeOrder>(
    id: string | null,
    props?: Utils.UseQueryOptions<RemovalChangeOrder, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<RemovalChangeOrderPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ContractItemEditRestrictionViewSetSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/get_contract_item_edit_restrictions/`

  // plugin buildResourceMethods
  static get = G.contractItemEditRestrictionViewSet

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/projects/get_contract_item_edit_restrictions/']

  static getQ = <TSelect = ContractItemEditRestrictionViewSetOutput>(
    props: Utils.UseQueryOptions<ContractItemEditRestrictionViewSetOutput, unknown, TSelect> & {
      params: ContractItemEditRestrictionViewSetParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class ContractEmbeddedFile2Spec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/contract_files/`

  // plugin buildResourceMethods
  static list = G.listContractEmbeddedFile2s

  static create = G.createContractEmbeddedFile2

  static retrieve = G.retrieveContractEmbeddedFile2

  static update = G.partialUpdateContractEmbeddedFile2

  static delete = G.destroyContractEmbeddedFile2

  static download = G.downloadContractEmbeddedFile2

  // plugin addResourceDependencies
  static downstream = ['/projects/contract_files/', '/projects/contracts/']

  // plugin addQuerySpecs
  static key = () => ['/projects/contract_files/']

  static listQ = <TSelect = ListContractEmbeddedFile2s200['results']>(
    props?: Utils.UseQueryOptions<ListContractEmbeddedFile2s200, unknown, TSelect> &
      Utils.Params<ListContractEmbeddedFile2sParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ContractEmbeddedFile2>(
    id: string | null,
    props?: Utils.UseQueryOptions<ContractEmbeddedFile2, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ContractEmbeddedFile2Create]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ContractEmbeddedFile2Patch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ContractItemSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/contract_items/`

  // plugin buildResourceMethods
  static list = G.listContractItems

  static create = G.createContractItem

  static retrieve = G.retrieveContractItem

  static update = G.partialUpdateContractItem

  static delete = G.destroyContractItem

  static archive_restrictions = G.getContractItemArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/get_retainage_values/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/budgeting/get_project_budget_cost/',
    '/page_resources/bill_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/change_orders/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/retainage_releases/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/contract_items/']

  static listQ = <TSelect = ListContractItems200['results']>(
    props?: Utils.UseQueryOptions<ListContractItems200, unknown, TSelect> &
      Utils.Params<ListContractItemsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ContractItem>(
    id: string | null,
    props?: Utils.UseQueryOptions<ContractItem, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = ArchiveRestriction>(
    id: string,
    props?: Utils.UseQueryOptions<ArchiveRestriction, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ContractItemCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ContractItemPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ContractSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/contracts/`

  // plugin buildResourceMethods
  static list = G.listContracts

  static create = G.createContract

  static retrieve = G.retrieveContract

  static update = G.partialUpdateContract

  static archive_restrictions = G.getContractArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/aggregated_payment_list_rows/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_retainage_values/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/budgeting/get_project_budget_cost/',
    '/compliance/compliance_status_summarys/',
    '/estimates/',
    '/estimates/get_next_estimate_number/',
    '/ocr/bill_ocr_raw_results/',
    '/page_resources/bill_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/contracts/']

  static listQ = <TSelect = ListContracts200['results']>(
    props?: Utils.UseQueryOptions<ListContracts200, unknown, TSelect> &
      Utils.Params<ListContractsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Contract>(
    id: string | null,
    props?: Utils.UseQueryOptions<Contract, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = ArchiveRestriction>(
    id: string,
    props?: Utils.UseQueryOptions<ArchiveRestriction, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ContractCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ContractPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class CostCodeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/cost_codes/`

  // plugin buildResourceMethods
  static list = G.listCostCodes

  static create = G.createCostCode

  static retrieve = G.retrieveCostCode

  static update = G.partialUpdateCostCode

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/accounting/income_codes/',
    '/accounting/items/',
    '/accounting/ledger_accounts/',
    '/admin/accounts/',
    '/admin/business_notes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/payment_sources/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/budget_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/external_communication_configs/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/businesses/stripe_connected_account_links/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_configs/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_programs/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
    '/ocr/bill_ocr_raw_result_line_items/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/onboarding/onboarding_surveys/',
    '/onboarding/terms_of_service_acceptances/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
    '/projects/cost_types/',
    '/projects/general_project_configs/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/cost_codes/']

  static listQ = <TSelect = ListCostCodes200['results']>(
    props?: Utils.UseQueryOptions<ListCostCodes200, unknown, TSelect> &
      Utils.Params<ListCostCodesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CostCode>(
    id: string | null,
    props?: Utils.UseQueryOptions<CostCode, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: CostCodeCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<CostCodePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class CostTypeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/cost_types/`

  // plugin buildResourceMethods
  static list = G.listCostTypes

  static create = G.createCostType

  static retrieve = G.retrieveCostType

  static update = G.partialUpdateCostType

  static archive_restrictions = G.getCostTypeArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_types/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/cost_types/']

  static listQ = <TSelect = ListCostTypes200['results']>(
    props?: Utils.UseQueryOptions<ListCostTypes200, unknown, TSelect> &
      Utils.Params<ListCostTypesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CostType>(
    id: string | null,
    props?: Utils.UseQueryOptions<CostType, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = ArchiveRestriction>(
    id: string,
    props?: Utils.UseQueryOptions<ArchiveRestriction, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: CostTypeCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<CostTypePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GeneralProjectConfigSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/general_project_configs/`

  // plugin buildResourceMethods
  static list = G.listGeneralProjectConfigs

  static create = G.createGeneralProjectConfig

  static retrieve = G.retrieveGeneralProjectConfig

  static update = G.partialUpdateGeneralProjectConfig

  static singleton = G.retrieveSingletonGeneralProjectConfig

  // plugin addResourceDependencies
  static downstream = ['/projects/general_project_configs/']

  // plugin addQuerySpecs
  static key = () => ['/projects/general_project_configs/']

  static listQ = <TSelect = ListGeneralProjectConfigs200['results']>(
    props?: Utils.UseQueryOptions<ListGeneralProjectConfigs200, unknown, TSelect> &
      Utils.Params<ListGeneralProjectConfigsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = GeneralProjectConfig>(
    id: string | null,
    props?: Utils.UseQueryOptions<GeneralProjectConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = GeneralProjectConfig>(
    props?: Utils.UseQueryOptions<GeneralProjectConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: GeneralProjectConfigCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<GeneralProjectConfigPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class RetainageReleaseSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/projects/retainage_releases/`

  // plugin buildResourceMethods
  static list = G.listRetainageReleases

  static create = G.createRetainageRelease

  static retrieve = G.retrieveRetainageRelease

  // plugin addResourceDependencies
  static downstream = [
    '/billing/contract_progress_summarys/',
    '/billing/get_retainage_values/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/page_resources/client_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/retainage_releases/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/projects/retainage_releases/']

  static listQ = <TSelect = ListRetainageReleases200['results']>(
    props?: Utils.UseQueryOptions<ListRetainageReleases200, unknown, TSelect> &
      Utils.Params<ListRetainageReleasesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = RetainageRelease>(
    id: string | null,
    props?: Utils.UseQueryOptions<RetainageRelease, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: RetainageReleaseCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InternalNoteFileLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/attachments/internal_note_file_links/`

  // plugin buildResourceMethods
  static list = G.listInternalNoteFileLinks

  static create = G.createInternalNoteFileLink

  static retrieve = G.retrieveInternalNoteFileLink

  static delete = G.destroyInternalNoteFileLink

  static download = G.downloadInternalNoteFileLink

  // plugin addResourceDependencies
  static downstream = ['/attachments/internal_note_file_links/', '/attachments/internal_notes/']

  // plugin addQuerySpecs
  static key = () => ['/attachments/internal_note_file_links/']

  static listQ = <TSelect = ListInternalNoteFileLinks200['results']>(
    props?: Utils.UseQueryOptions<ListInternalNoteFileLinks200, unknown, TSelect> &
      Utils.Params<ListInternalNoteFileLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InternalNoteFileLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<InternalNoteFileLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InternalNoteFileLinkCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InternalNoteSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/attachments/internal_notes/`

  // plugin buildResourceMethods
  static list = G.listInternalNotes

  static create = G.createInternalNote

  static retrieve = G.retrieveInternalNote

  static update = G.partialUpdateInternalNote

  static delete = G.destroyInternalNote

  // plugin addResourceDependencies
  static downstream = [
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/attachments/internal_notes/']

  static listQ = <TSelect = ListInternalNotes200['results']>(
    props?: Utils.UseQueryOptions<ListInternalNotes200, unknown, TSelect> &
      Utils.Params<ListInternalNotesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InternalNote>(
    id: string | null,
    props?: Utils.UseQueryOptions<InternalNote, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InternalNoteCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InternalNotePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class NoteFileLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/attachments/note_file_links/`

  // plugin buildResourceMethods
  static list = G.listNoteFileLinks

  static create = G.createNoteFileLink

  static retrieve = G.retrieveNoteFileLink

  static download = G.downloadNoteFileLink

  // plugin addResourceDependencies
  static downstream = ['/attachments/note_file_links/', '/attachments/notes/']

  // plugin addQuerySpecs
  static key = () => ['/attachments/note_file_links/']

  static listQ = <TSelect = ListNoteFileLinks200['results']>(
    props?: Utils.UseQueryOptions<ListNoteFileLinks200, unknown, TSelect> &
      Utils.Params<ListNoteFileLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = NoteFileLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<NoteFileLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: NoteFileLinkCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class NoteMentionLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/attachments/note_mention_links/`

  // plugin buildResourceMethods
  static list = G.listNoteMentionLinks

  static create = G.createNoteMentionLink

  static retrieve = G.retrieveNoteMentionLink

  // plugin addResourceDependencies
  static downstream = [
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/invoice_list_rows/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/attachments/note_mention_links/']

  static listQ = <TSelect = ListNoteMentionLinks200['results']>(
    props?: Utils.UseQueryOptions<ListNoteMentionLinks200, unknown, TSelect> &
      Utils.Params<ListNoteMentionLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = NoteMentionLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<NoteMentionLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: NoteMentionLinkCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class NoteSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/attachments/notes/`

  // plugin buildResourceMethods
  static list = G.listNotes

  static create = G.createNote

  static retrieve = G.retrieveNote

  static delete = G.destroyNote

  // plugin addResourceDependencies
  static downstream = [
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/invoice_list_rows/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/attachments/notes/']

  static listQ = <TSelect = ListNotes200['results']>(
    props?: Utils.UseQueryOptions<ListNotes200, unknown, TSelect> & Utils.Params<ListNotesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Note>(
    id: string | null,
    props?: Utils.UseQueryOptions<Note, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: NoteCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BaseInvoiceEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/files/`

  // plugin buildResourceMethods
  static list = G.listBaseInvoiceEmbeddedFiles

  static create = G.createBaseInvoiceEmbeddedFile

  static retrieve = G.retrieveBaseInvoiceEmbeddedFile

  static delete = G.destroyBaseInvoiceEmbeddedFile

  static download = G.retrieveBaseInvoiceEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/files/']

  static listQ = <TSelect = ListBaseInvoiceEmbeddedFiles200['results']>(
    props?: Utils.UseQueryOptions<ListBaseInvoiceEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListBaseInvoiceEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BaseInvoiceEmbeddedPrimaryFileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/primary_files/`

  // plugin buildResourceMethods
  static list = G.listBaseInvoiceEmbeddedPrimaryFiles

  static create = G.createBaseInvoiceEmbeddedPrimaryFile

  static retrieve = G.retrieveBaseInvoiceEmbeddedPrimaryFile

  static delete = G.destroyBaseInvoiceEmbeddedPrimaryFile

  static download = G.retrieveBaseInvoiceEmbeddedPrimaryFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/primary_files/']

  static listQ = <TSelect = ListBaseInvoiceEmbeddedPrimaryFiles200['results']>(
    props?: Utils.UseQueryOptions<ListBaseInvoiceEmbeddedPrimaryFiles200, unknown, TSelect> &
      Utils.Params<ListBaseInvoiceEmbeddedPrimaryFilesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BillEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (invoiceId: string) => `/billing/bills/${invoiceId}/files/`

  // plugin buildResourceMethods
  static list = G.listBillEmbeddedFiles

  static create = G.createBillEmbeddedFile

  static retrieve = G.retrieveBillEmbeddedFile

  static delete = G.destroyBillEmbeddedFile

  static download = G.retrieveBillEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (invoiceId: string) => ['/billing/bills/', `${invoiceId}`, '/files/']

  static listQ = <TSelect = ListBillEmbeddedFiles200['results']>(
    invoiceId: string,
    props?: Utils.UseQueryOptions<ListBillEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListBillEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(invoiceId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    invoiceId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(invoiceId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    invoiceId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(invoiceId, id)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [invoiceId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [invoiceId: string, id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BillEmbeddedPrimaryFileSpec {
  // plugin addBaseUrl
  static baseUrl = (invoiceId: string) => `/billing/bills/${invoiceId}/primary_files/`

  // plugin buildResourceMethods
  static list = G.listBillEmbeddedPrimaryFiles

  static create = G.createBillEmbeddedPrimaryFile

  static retrieve = G.retrieveBillEmbeddedPrimaryFile

  static delete = G.destroyBillEmbeddedPrimaryFile

  static download = G.retrieveBillEmbeddedPrimaryFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (invoiceId: string) => ['/billing/bills/', `${invoiceId}`, '/primary_files/']

  static listQ = <TSelect = ListBillEmbeddedPrimaryFiles200['results']>(
    invoiceId: string,
    props?: Utils.UseQueryOptions<ListBillEmbeddedPrimaryFiles200, unknown, TSelect> &
      Utils.Params<ListBillEmbeddedPrimaryFilesParams>,
  ) => {
    const queryFn = () => this.list(invoiceId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    invoiceId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(invoiceId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    invoiceId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(invoiceId, id)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [invoiceId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [invoiceId: string, id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BillSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/bills/`

  // plugin buildResourceMethods
  static list = G.listBills

  static create = G.createBill

  static retrieve = G.retrieveBill

  static update = G.partialUpdateBill

  static delete = G.destroyBill

  static pdf = G.retrieveBillPdf

  static zip = G.retrieveBillZip

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/compliance/compliance_status_summarys/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/projects/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/bills/']

  static listQ = <TSelect = ListBills200['results']>(
    props?: Utils.UseQueryOptions<ListBills200, unknown, TSelect> & Utils.Params<ListBillsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Bill>(
    id: string | null,
    props?: Utils.UseQueryOptions<Bill, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static pdfQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.pdf(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'pdf']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  static zipQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.zip(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'zip']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BillCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<BillPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ContractProgressSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/contract_progress_summarys/`

  // plugin buildResourceMethods
  static list = G.listContractProgressSummarys

  static retrieve = G.retrieveContractProgressSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/contract_progress_summarys/']

  static listQ = <TSelect = ListContractProgressSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListContractProgressSummarys200, unknown, TSelect> &
      Utils.Params<ListContractProgressSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ContractProgressSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<ContractProgressSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ExternalPaymentSourceSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/external_payment_sources/`

  // plugin buildResourceMethods
  static list = G.listExternalPaymentSources

  static retrieve = G.retrieveExternalPaymentSource

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/external_payment_sources/']

  static listQ = <TSelect = ListExternalPaymentSources200['results']>(
    props?: Utils.UseQueryOptions<ListExternalPaymentSources200, unknown, TSelect> &
      Utils.Params<ListExternalPaymentSourcesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ExternalPaymentSource>(
    id: string | null,
    props?: Utils.UseQueryOptions<ExternalPaymentSource, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class FreeFormInvoiceLineItemSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/free_form_invoice_line_items/`

  // plugin buildResourceMethods
  static list = G.listFreeFormInvoiceLineItems

  static retrieve = G.retrieveFreeFormInvoiceLineItem

  static update = G.partialUpdateFreeFormInvoiceLineItem

  // plugin addResourceDependencies
  static downstream = [
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_retainage_values/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/free_form_invoice_line_items/']

  static listQ = <TSelect = ListFreeFormInvoiceLineItems200['results']>(
    props?: Utils.UseQueryOptions<ListFreeFormInvoiceLineItems200, unknown, TSelect> &
      Utils.Params<ListFreeFormInvoiceLineItemsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = FreeFormInvoiceLineItem>(
    id: string | null,
    props?: Utils.UseQueryOptions<FreeFormInvoiceLineItem, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<FreeFormInvoiceLineItemPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceActionRequestSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_action_requests/`

  // plugin buildResourceMethods
  static list = G.listInvoiceActionRequests

  static create = G.createInvoiceActionRequest

  static retrieve = G.retrieveInvoiceActionRequest

  // plugin addResourceDependencies
  static downstream = [
    '/billing/invoice_action_requests/',
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/invoice_list_rows/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_action_requests/']

  static listQ = <TSelect = ListInvoiceActionRequests200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceActionRequests200, unknown, TSelect> &
      Utils.Params<ListInvoiceActionRequestsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceActionRequest>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceActionRequest, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceActionRequestCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceApprovalActionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_approval_actions/`

  // plugin buildResourceMethods
  static list = G.listInvoiceApprovalActions

  static create = G.createInvoiceApprovalAction

  static retrieve = G.retrieveInvoiceApprovalAction

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/estimates/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/get_next_estimate_number/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/change_orders/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_approval_actions/']

  static listQ = <TSelect = ListInvoiceApprovalActions200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceApprovalActions200, unknown, TSelect> &
      Utils.Params<ListInvoiceApprovalActionsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceApprovalAction>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceApprovalAction, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceApprovalActionCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceConfigSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_configs/`

  // plugin buildResourceMethods
  static list = G.listInvoiceConfigs

  static singleton = G.retrieveSingletonInvoiceConfig

  static retrieve = G.retrieveInvoiceConfig

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_configs/']

  static listQ = <TSelect = ListInvoiceConfigs200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceConfigs200, unknown, TSelect> &
      Utils.Params<ListInvoiceConfigsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = InvoiceConfig>(
    props?: Utils.UseQueryOptions<InvoiceConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceConfig>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoiceEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (invoiceId: string) => `/billing/invoices/${invoiceId}/files/`

  // plugin buildResourceMethods
  static list = G.listInvoiceEmbeddedFiles

  static create = G.createInvoiceEmbeddedFile

  static retrieve = G.retrieveInvoiceEmbeddedFile

  static delete = G.destroyInvoiceEmbeddedFile

  static download = G.retrieveInvoiceEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (invoiceId: string) => ['/billing/invoices/', `${invoiceId}`, '/files/']

  static listQ = <TSelect = ListInvoiceEmbeddedFiles200['results']>(
    invoiceId: string,
    props?: Utils.UseQueryOptions<ListInvoiceEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListInvoiceEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(invoiceId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    invoiceId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(invoiceId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    invoiceId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(invoiceId, id)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [invoiceId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [invoiceId: string, id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceEmbeddedPrimaryFileSpec {
  // plugin addBaseUrl
  static baseUrl = (invoiceId: string) => `/billing/invoices/${invoiceId}/primary_files/`

  // plugin buildResourceMethods
  static list = G.listInvoiceEmbeddedPrimaryFiles

  static create = G.createInvoiceEmbeddedPrimaryFile

  static retrieve = G.retrieveInvoiceEmbeddedPrimaryFile

  static delete = G.destroyInvoiceEmbeddedPrimaryFile

  static download = G.retrieveInvoiceEmbeddedPrimaryFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (invoiceId: string) => ['/billing/invoices/', `${invoiceId}`, '/primary_files/']

  static listQ = <TSelect = ListInvoiceEmbeddedPrimaryFiles200['results']>(
    invoiceId: string,
    props?: Utils.UseQueryOptions<ListInvoiceEmbeddedPrimaryFiles200, unknown, TSelect> &
      Utils.Params<ListInvoiceEmbeddedPrimaryFilesParams>,
  ) => {
    const queryFn = () => this.list(invoiceId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    invoiceId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(invoiceId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    invoiceId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(invoiceId, id)
    const queryKey: Utils.QueryKey = [...this.key(invoiceId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [invoiceId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [invoiceId: string, id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceLineItemSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_line_items/`

  // plugin buildResourceMethods
  static list = G.listInvoiceLineItems

  static create = G.createInvoiceLineItem

  static retrieve = G.retrieveInvoiceLineItem

  static update = G.partialUpdateInvoiceLineItem

  static delete = G.destroyInvoiceLineItem

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/compliance/compliance_status_summarys/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/projects/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_line_items/']

  static listQ = <TSelect = ListInvoiceLineItems200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceLineItems200, unknown, TSelect> &
      Utils.Params<ListInvoiceLineItemsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceLineItem>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceLineItem, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceLineItemCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoiceLineItemPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoicePaymentGroupSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_payment_groups/`

  // plugin buildResourceMethods
  static list = G.listInvoicePaymentGroups

  static create = G.createInvoicePaymentGroup

  static retrieve = G.retrieveInvoicePaymentGroup

  static update = G.partialUpdateInvoicePaymentGroup

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/banking/get_statement_pdf/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_payment_groups/']

  static listQ = <TSelect = ListInvoicePaymentGroups200['results']>(
    props?: Utils.UseQueryOptions<ListInvoicePaymentGroups200, unknown, TSelect> &
      Utils.Params<ListInvoicePaymentGroupsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoicePaymentGroup>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoicePaymentGroup, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoicePaymentGroupCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoicePaymentGroupPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoicePaymentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_payments/`

  // plugin buildResourceMethods
  static list = G.listInvoicePayments

  static create = G.createInvoicePayment

  static retrieve = G.retrieveInvoicePayment

  static update = G.partialUpdateInvoicePayment

  static delete = G.destroyInvoicePayment

  static archive_restrictions = G.getInvoicePaymentArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/businesses/outbound_invites/',
    '/estimates/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/get_next_estimate_number/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_payments/']

  static listQ = <TSelect = ListInvoicePayments200['results']>(
    props?: Utils.UseQueryOptions<ListInvoicePayments200, unknown, TSelect> &
      Utils.Params<ListInvoicePaymentsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoicePayment>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoicePayment, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = GetArchiveRestrictionsOutput>(
    id: string,
    props?: Utils.UseQueryOptions<GetArchiveRestrictionsOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoicePaymentCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoicePaymentPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GetInvoicePaymentArchiveRestrictionsListSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_payments/archive_restrictions/`

  // plugin buildResourceMethods
  static get = G.getInvoicePaymentArchiveRestrictionsList

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_payments/archive_restrictions/']

  static getQ = <TSelect = GetInvoicePaymentArchiveRestrictionsList200>(
    props: Utils.UseQueryOptions<GetInvoicePaymentArchiveRestrictionsList200, unknown, TSelect> & {
      params: GetInvoicePaymentArchiveRestrictionsListParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoicePermissionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_permissions/`

  // plugin buildResourceMethods
  static list = G.listInvoicePermissions

  static retrieve = G.retrieveInvoicePermission

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_permissions/']

  static listQ = <TSelect = ListInvoicePermissions200['results']>(
    props?: Utils.UseQueryOptions<ListInvoicePermissions200, unknown, TSelect> &
      Utils.Params<ListInvoicePermissionsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoicePermission>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoicePermission, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoiceSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoices/`

  // plugin buildResourceMethods
  static list = G.listInvoices

  static create = G.createInvoice

  static retrieve = G.retrieveInvoice

  static update = G.partialUpdateInvoice

  static delete = G.destroyInvoice

  static pdf = G.retrieveInvoicePdf

  static zip = G.retrieveInvoiceZip

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/banking/get_statement_pdf/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/compliance/compliance_status_summarys/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/projects/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoices/']

  static listQ = <TSelect = ListInvoices200['results']>(
    props?: Utils.UseQueryOptions<ListInvoices200, unknown, TSelect> &
      Utils.Params<ListInvoicesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Invoice>(
    id: string | null,
    props?: Utils.UseQueryOptions<Invoice, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static pdfQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.pdf(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'pdf']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  static zipQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.zip(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'zip']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoicePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class LienWaiverClaimantSignatureSpec {
  // plugin addBaseUrl
  static baseUrl = (lienWaiverId: string) =>
    `/billing/lien_waivers/${lienWaiverId}/claimant_signature/`

  // plugin buildResourceMethods
  static list = G.listLienWaiverClaimantSignatures

  static retrieve = G.retrieveLienWaiverClaimantSignature

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (lienWaiverId: string) => [
    '/billing/lien_waivers/',
    `${lienWaiverId}`,
    '/claimant_signature/',
  ]

  static listQ = <TSelect = ListLienWaiverClaimantSignatures200['results']>(
    lienWaiverId: string,
    props?: Utils.UseQueryOptions<ListLienWaiverClaimantSignatures200, unknown, TSelect> &
      Utils.Params<ListLienWaiverClaimantSignaturesParams>,
  ) => {
    const queryFn = () => this.list(lienWaiverId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = LienWaiverClaimantSignature>(
    lienWaiverId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<LienWaiverClaimantSignature, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(lienWaiverId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class LienWaiverEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (lienWaiverId: string) => `/billing/lien_waivers/${lienWaiverId}/files/`

  // plugin buildResourceMethods
  static list = G.listLienWaiverEmbeddedFiles

  static create = G.createLienWaiverEmbeddedFile

  static retrieve = G.retrieveLienWaiverEmbeddedFile

  static delete = G.destroyLienWaiverEmbeddedFile

  static download = G.retrieveLienWaiverEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (lienWaiverId: string) => ['/billing/lien_waivers/', `${lienWaiverId}`, '/files/']

  static listQ = <TSelect = ListLienWaiverEmbeddedFiles200['results']>(
    lienWaiverId: string,
    props?: Utils.UseQueryOptions<ListLienWaiverEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListLienWaiverEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(lienWaiverId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    lienWaiverId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(lienWaiverId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    lienWaiverId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(lienWaiverId, id)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [lienWaiverId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [lienWaiverId: string, id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class LienWaiverEmbeddedPrimaryFileSpec {
  // plugin addBaseUrl
  static baseUrl = (lienWaiverId: string) => `/billing/lien_waivers/${lienWaiverId}/primary_files/`

  // plugin buildResourceMethods
  static list = G.listLienWaiverEmbeddedPrimaryFiles

  static create = G.createLienWaiverEmbeddedPrimaryFile

  static retrieve = G.retrieveLienWaiverEmbeddedPrimaryFile

  static delete = G.destroyLienWaiverEmbeddedPrimaryFile

  static download = G.retrieveLienWaiverEmbeddedPrimaryFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (lienWaiverId: string) => [
    '/billing/lien_waivers/',
    `${lienWaiverId}`,
    '/primary_files/',
  ]

  static listQ = <TSelect = ListLienWaiverEmbeddedPrimaryFiles200['results']>(
    lienWaiverId: string,
    props?: Utils.UseQueryOptions<ListLienWaiverEmbeddedPrimaryFiles200, unknown, TSelect> &
      Utils.Params<ListLienWaiverEmbeddedPrimaryFilesParams>,
  ) => {
    const queryFn = () => this.list(lienWaiverId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    lienWaiverId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(lienWaiverId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    lienWaiverId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(lienWaiverId, id)
    const queryKey: Utils.QueryKey = [...this.key(lienWaiverId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [lienWaiverId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [lienWaiverId: string, id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class LienWaiverSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/lien_waivers/`

  // plugin buildResourceMethods
  static list = G.listLienWaivers

  static create = G.createLienWaiver

  static retrieve = G.retrieveLienWaiver

  static update = G.partialUpdateLienWaiver

  static pdf = G.retrieveLienWaiverPdf

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/project_expenses/',
    '/compliance/compliance_documents/',
    '/estimates/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/employee_profiles/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/projects/contract_files/',
    '/projects/contracts/',
    '/projects/logos/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/lien_waivers/']

  static listQ = <TSelect = ListLienWaivers200['results']>(
    props?: Utils.UseQueryOptions<ListLienWaivers200, unknown, TSelect> &
      Utils.Params<ListLienWaiversParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = LienWaiver>(
    id: string | null,
    props?: Utils.UseQueryOptions<LienWaiver, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static pdfQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.pdf(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'pdf']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: LienWaiverCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<LienWaiverPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class PrimeContractInvoiceEmailSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/prime_contract_invoice_emails/`

  // plugin buildResourceMethods
  static list = G.listPrimeContractInvoiceEmails

  static retrieve = G.retrievePrimeContractInvoiceEmail

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/prime_contract_invoice_emails/']

  static listQ = <TSelect = ListPrimeContractInvoiceEmails200['results']>(
    props?: Utils.UseQueryOptions<ListPrimeContractInvoiceEmails200, unknown, TSelect> &
      Utils.Params<ListPrimeContractInvoiceEmailsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PrimeContractInvoiceEmail>(
    id: string | null,
    props?: Utils.UseQueryOptions<PrimeContractInvoiceEmail, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class PrimeContractInvoiceEmailViewSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/prime_contract_invoice_email_views/`

  // plugin buildResourceMethods
  static list = G.listPrimeContractInvoiceEmailViews

  static retrieve = G.retrievePrimeContractInvoiceEmailView

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/prime_contract_invoice_email_views/']

  static listQ = <TSelect = ListPrimeContractInvoiceEmailViews200['results']>(
    props?: Utils.UseQueryOptions<ListPrimeContractInvoiceEmailViews200, unknown, TSelect> &
      Utils.Params<ListPrimeContractInvoiceEmailViewsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PrimeContractInvoiceEmailView>(
    id: string | null,
    props?: Utils.UseQueryOptions<PrimeContractInvoiceEmailView, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class PrimeContractInvoicePaymentPageVisitSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/prime_contract_invoice_payment_page_visits/`

  // plugin buildResourceMethods
  static list = G.listPrimeContractInvoicePaymentPageVisits

  static create = G.createPrimeContractInvoicePaymentPageVisit

  static retrieve = G.retrievePrimeContractInvoicePaymentPageVisit

  // plugin addResourceDependencies
  static downstream = ['/billing/prime_contract_invoice_payment_page_visits/']

  // plugin addQuerySpecs
  static key = () => ['/billing/prime_contract_invoice_payment_page_visits/']

  static listQ = <TSelect = ListPrimeContractInvoicePaymentPageVisits200['results']>(
    props?: Utils.UseQueryOptions<ListPrimeContractInvoicePaymentPageVisits200, unknown, TSelect> &
      Utils.Params<ListPrimeContractInvoicePaymentPageVisitsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PrimeContractInvoicePaymentPageVisit>(
    id: string | null,
    props?: Utils.UseQueryOptions<PrimeContractInvoicePaymentPageVisit, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: PrimeContractInvoicePaymentPageVisitCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ProjectExpensePermissionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/project_expense_permissions/`

  // plugin buildResourceMethods
  static list = G.listProjectExpensePermissions

  static retrieve = G.retrieveProjectExpensePermission

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/project_expense_permissions/']

  static listQ = <TSelect = ListProjectExpensePermissions200['results']>(
    props?: Utils.UseQueryOptions<ListProjectExpensePermissions200, unknown, TSelect> &
      Utils.Params<ListProjectExpensePermissionsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectExpensePermission>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectExpensePermission, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ProjectExpenseReceiptEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (expenseId: string) => `/billing/project_expenses/${expenseId}/receipts/`

  // plugin buildResourceMethods
  static list = G.listProjectExpenseReceiptEmbeddedFiles

  static create = G.createProjectExpenseReceiptEmbeddedFile

  static retrieve = G.retrieveProjectExpenseReceiptEmbeddedFile

  static delete = G.destroyProjectExpenseReceiptEmbeddedFile

  static download = G.retrieveProjectExpenseReceiptEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (expenseId: string) => ['/billing/project_expenses/', `${expenseId}`, '/receipts/']

  static listQ = <TSelect = ListProjectExpenseReceiptEmbeddedFiles200['results']>(
    expenseId: string,
    props?: Utils.UseQueryOptions<ListProjectExpenseReceiptEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListProjectExpenseReceiptEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(expenseId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(expenseId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    expenseId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(expenseId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(expenseId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    expenseId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(expenseId, id)
    const queryKey: Utils.QueryKey = [...this.key(expenseId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [expenseId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [expenseId: string, id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ProjectExpenseSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/project_expenses/`

  // plugin buildResourceMethods
  static list = G.listProjectExpenses

  static create = G.createProjectExpense

  static retrieve = G.retrieveProjectExpense

  static update = G.partialUpdateProjectExpense

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/compliance/compliance_status_summarys/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/projects/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/project_expenses/']

  static listQ = <TSelect = ListProjectExpenses200['results']>(
    props?: Utils.UseQueryOptions<ListProjectExpenses200, unknown, TSelect> &
      Utils.Params<ListProjectExpensesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectExpense>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectExpense, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ProjectExpenseCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ProjectExpensePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ProjectProgressSummaryV2Spec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/project_progress_summary_v2s/`

  // plugin buildResourceMethods
  static list = G.listProjectProgressSummaryV2s

  static retrieve = G.retrieveProjectProgressSummaryV2

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/project_progress_summary_v2s/']

  static listQ = <TSelect = ListProjectProgressSummaryV2s200['results']>(
    props?: Utils.UseQueryOptions<ListProjectProgressSummaryV2s200, unknown, TSelect> &
      Utils.Params<ListProjectProgressSummaryV2sParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectProgressSummaryV2>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectProgressSummaryV2, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ScheduleOfValuesItemProgressSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/schedule_of_values_item_progress_summaries/`

  // plugin buildResourceMethods
  static list = G.listScheduleOfValuesItemProgressSummarys

  static retrieve = G.retrieveScheduleOfValuesItemProgressSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/schedule_of_values_item_progress_summaries/']

  static listQ = <TSelect = ListScheduleOfValuesItemProgressSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListScheduleOfValuesItemProgressSummarys200, unknown, TSelect> &
      Utils.Params<ListScheduleOfValuesItemProgressSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ScheduleOfValuesItemProgressSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<ScheduleOfValuesItemProgressSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetExpenseMetricsSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_expense_metrics/`

  // plugin buildResourceMethods
  static get = G.getExpenseMetrics

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_expense_metrics/']

  static getQ = <TSelect = GetExpenseMetricsOutput>(
    props?: Utils.UseQueryOptions<GetExpenseMetricsOutput, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetInvoiceApprovalWorkflowRequirementsSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_invoice_approval_workflow_requirements/`

  // plugin buildResourceMethods
  static get = G.getInvoiceApprovalWorkflowRequirements

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_invoice_approval_workflow_requirements/']

  static getQ = <TSelect = GetInvoiceApprovalWorkflowRequirementsViewOutput>(
    props: Utils.UseQueryOptions<
      GetInvoiceApprovalWorkflowRequirementsViewOutput,
      unknown,
      TSelect
    > & { params: GetInvoiceApprovalWorkflowRequirementsParams },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetInvoiceCardFeeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_invoice_card_fee/`

  // plugin buildResourceMethods
  static get = G.getInvoiceCardFee

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_invoice_card_fee/']

  static getQ = <TSelect = GetInvoiceCardFeeOutput>(
    props: Utils.UseQueryOptions<GetInvoiceCardFeeOutput, unknown, TSelect> & {
      params: GetInvoiceCardFeeParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetInvoiceFileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_invoice_file/`

  // plugin buildResourceMethods
  static get = G.getInvoiceFile

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_invoice_file/']

  static getQ = <TSelect = Blob>(
    props: Utils.UseQueryOptions<Blob, unknown, TSelect> & { params: GetInvoiceFileParams },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetInvoicePaymentStartDateSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_invoice_payment_start_date/`

  // plugin buildResourceMethods
  static get = G.getInvoicePaymentStartDate

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_invoice_payment_start_date/']

  static getQ = <TSelect = GetInvoicePaymentStartDateViewOutput>(
    props: Utils.UseQueryOptions<GetInvoicePaymentStartDateViewOutput, unknown, TSelect> & {
      params: GetInvoicePaymentStartDateParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetLienWaiverPaymentBlockersSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_lien_waiver_payment_blockers/`

  // plugin buildResourceMethods
  static get = G.getLienWaiverPaymentBlockers

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_lien_waiver_payment_blockers/']

  static getQ = <TSelect = GetLienWaiverPaymentBlockersOutput>(
    props: Utils.UseQueryOptions<GetLienWaiverPaymentBlockersOutput, unknown, TSelect> & {
      params: GetLienWaiverPaymentBlockersParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetLienWaiverPdfPreviewV2Spec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_lien_waiver_pdf_preview_v2/`

  // plugin buildResourceMethods
  static get = G.getLienWaiverPdfPreviewV2

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_lien_waiver_pdf_preview_v2/']

  static getQ = <TSelect = Blob>(
    props: Utils.UseQueryOptions<Blob, unknown, TSelect> & {
      params: GetLienWaiverPdfPreviewV2Params
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetNextInvoiceNumberSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_next_invoice_number/`

  // plugin buildResourceMethods
  static get = G.getNextInvoiceNumber

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_next_invoice_number/']

  static getQ = <TSelect = GetNextInvoiceNumberOutput>(
    props?: Utils.UseQueryOptions<GetNextInvoiceNumberOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetRetainageValuesSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/get_retainage_values/`

  // plugin buildResourceMethods
  static get = G.getRetainageValues

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/get_retainage_values/']

  static getQ = <TSelect = GetRetainageValuesViewOutput>(
    props: Utils.UseQueryOptions<GetRetainageValuesViewOutput, unknown, TSelect> & {
      params: GetRetainageValuesParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class PredictUncategorizedExpenseDetailsSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/predict_uncategorized_expense_details/`

  // plugin buildResourceMethods
  static get = G.predictUncategorizedExpenseDetails

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/predict_uncategorized_expense_details/']

  static getQ = <TSelect = PredictUncategorizedExpenseDetailsOutput>(
    props: Utils.UseQueryOptions<PredictUncategorizedExpenseDetailsOutput, unknown, TSelect> & {
      params: PredictUncategorizedExpenseDetailsParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoiceApprovalRuleSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_approval_rules/`

  // plugin buildResourceMethods
  static list = G.listInvoiceApprovalRules

  static create = G.createInvoiceApprovalRule

  static retrieve = G.retrieveInvoiceApprovalRule

  static update = G.partialUpdateInvoiceApprovalRule

  static delete = G.destroyInvoiceApprovalRule

  // plugin addResourceDependencies
  static downstream = [
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_permissions/',
    '/page_resources/bill_list_rows/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_approval_rules/']

  static listQ = <TSelect = ListInvoiceApprovalRules200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceApprovalRules200, unknown, TSelect> &
      Utils.Params<ListInvoiceApprovalRulesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceApprovalRule>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceApprovalRule, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceApprovalRuleCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoiceApprovalRulePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceIntentEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_intent_files/`

  // plugin buildResourceMethods
  static list = G.listInvoiceIntentEmbeddedFiles

  static create = G.createInvoiceIntentEmbeddedFile

  static retrieve = G.retrieveInvoiceIntentEmbeddedFile

  static update = G.partialUpdateInvoiceIntentEmbeddedFile

  static download = G.downloadInvoiceIntentEmbeddedFile

  // plugin addResourceDependencies
  static downstream = ['/billing/invoice_intent_files/', '/billing/invoice_intents/']

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_intent_files/']

  static listQ = <TSelect = ListInvoiceIntentEmbeddedFiles200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceIntentEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListInvoiceIntentEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceIntentEmbeddedFile>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceIntentEmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceIntentEmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoiceIntentEmbeddedFilePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceIntentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_intents/`

  // plugin buildResourceMethods
  static list = G.listInvoiceIntents

  static create = G.createInvoiceIntent

  static retrieve = G.retrieveInvoiceIntent

  static update = G.partialUpdateInvoiceIntent

  static delete = G.destroyInvoiceIntent

  // plugin addResourceDependencies
  static downstream = [
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_intents/']

  static listQ = <TSelect = ListInvoiceIntents200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceIntents200, unknown, TSelect> &
      Utils.Params<ListInvoiceIntentsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceIntent>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceIntent, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceIntentCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoiceIntentPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceLineItemMarkupLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_line_item_markup_links/`

  // plugin buildResourceMethods
  static list = G.listInvoiceLineItemMarkupLinks

  static create = G.createInvoiceLineItemMarkupLink

  static retrieve = G.retrieveInvoiceLineItemMarkupLink

  static update = G.partialUpdateInvoiceLineItemMarkupLink

  static delete = G.destroyInvoiceLineItemMarkupLink

  // plugin addResourceDependencies
  static downstream = [
    '/billing/bills/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/work_completed_sources/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_line_item_markup_links/']

  static listQ = <TSelect = ListInvoiceLineItemMarkupLinks200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceLineItemMarkupLinks200, unknown, TSelect> &
      Utils.Params<ListInvoiceLineItemMarkupLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceLineItemMarkupLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceLineItemMarkupLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InvoiceLineItemMarkupLinkCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<InvoiceLineItemMarkupLinkPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoicePrefillLineItemSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_prefill_line_items/`

  // plugin buildResourceMethods
  static list = G.listInvoicePrefillLineItems

  static retrieve = G.retrieveInvoicePrefillLineItem

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_prefill_line_items/']

  static listQ = <TSelect = ListInvoicePrefillLineItems200['results']>(
    props?: Utils.UseQueryOptions<ListInvoicePrefillLineItems200, unknown, TSelect> &
      Utils.Params<ListInvoicePrefillLineItemsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoicePrefillLineItem>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoicePrefillLineItem, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoicePrefillSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_prefills/`

  // plugin buildResourceMethods
  static list = G.listInvoicePrefills

  static retrieve = G.retrieveInvoicePrefill

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_prefills/']

  static listQ = <TSelect = ListInvoicePrefills200['results']>(
    props?: Utils.UseQueryOptions<ListInvoicePrefills200, unknown, TSelect> &
      Utils.Params<ListInvoicePrefillsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoicePrefill>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoicePrefill, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class PaymentSourceSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/payment_sources/`

  // plugin buildResourceMethods
  static list = G.listPaymentSources

  static create = G.createPaymentSource

  static retrieve = G.retrievePaymentSource

  static update = G.partialUpdatePaymentSource

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/billing/external_payment_sources/',
    '/billing/payment_sources/',
    '/billing/project_expenses/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/payment_sources/']

  static listQ = <TSelect = ListPaymentSources200['results']>(
    props?: Utils.UseQueryOptions<ListPaymentSources200, unknown, TSelect> &
      Utils.Params<ListPaymentSourcesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PaymentSource>(
    id: string | null,
    props?: Utils.UseQueryOptions<PaymentSource, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: PaymentSourceCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<PaymentSourcePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class TimeEntrySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/time_entries/`

  // plugin buildResourceMethods
  static list = G.listTimeEntrys

  static create = G.createTimeEntry

  static retrieve = G.retrieveTimeEntry

  static update = G.partialUpdateTimeEntry

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/billing/bills/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoices/',
    '/billing/time_entries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/time_entries/']

  static listQ = <TSelect = ListTimeEntrys200['results']>(
    props?: Utils.UseQueryOptions<ListTimeEntrys200, unknown, TSelect> &
      Utils.Params<ListTimeEntrysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = TimeEntry>(
    id: string | null,
    props?: Utils.UseQueryOptions<TimeEntry, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: TimeEntryCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<TimeEntryPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class VendorBillingConfigSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/vendor_billing_configs/`

  // plugin buildResourceMethods
  static list = G.listVendorBillingConfigs

  static create = G.createVendorBillingConfig

  static retrieve = G.retrieveVendorBillingConfig

  static update = G.partialUpdateVendorBillingConfig

  // plugin addResourceDependencies
  static downstream = [
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/vendor_billing_configs/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/projects/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/vendor_billing_configs/']

  static listQ = <TSelect = ListVendorBillingConfigs200['results']>(
    props?: Utils.UseQueryOptions<ListVendorBillingConfigs200, unknown, TSelect> &
      Utils.Params<ListVendorBillingConfigsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = VendorBillingConfig>(
    id: string | null,
    props?: Utils.UseQueryOptions<VendorBillingConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: VendorBillingConfigCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<VendorBillingConfigPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class WorkCompletedSourceSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/work_completed_sources/`

  // plugin buildResourceMethods
  static list = G.listWorkCompletedSources

  static create = G.createWorkCompletedSource

  static retrieve = G.retrieveWorkCompletedSource

  static update = G.partialUpdateWorkCompletedSource

  static delete = G.destroyWorkCompletedSource

  // plugin addResourceDependencies
  static downstream = [
    '/billing/bills/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_invoice_file/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/work_completed_sources/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/work_completed_sources/']

  static listQ = <TSelect = ListWorkCompletedSources200['results']>(
    props?: Utils.UseQueryOptions<ListWorkCompletedSources200, unknown, TSelect> &
      Utils.Params<ListWorkCompletedSourcesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = WorkCompletedSource>(
    id: string | null,
    props?: Utils.UseQueryOptions<WorkCompletedSource, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: WorkCompletedSourceCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<WorkCompletedSourcePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class DummysSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/dummy/`

  // plugin buildResourceMethods
  static list = G.listDummys

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/users/dummy/']

  static listQ = <TSelect = ListDummys200['results']>(
    props?: Utils.UseQueryOptions<ListDummys200, unknown, TSelect> & Utils.Params<ListDummysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class EmailVerificationAttemptSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/email_verification_attempts/`

  // plugin buildResourceMethods
  static list = G.listEmailVerificationAttempts

  static create = G.createEmailVerificationAttempt

  static retrieve = G.retrieveEmailVerificationAttempt

  // plugin addResourceDependencies
  static downstream = [
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/invoice_list_rows/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/users/email_verification_attempts/']

  static listQ = <TSelect = ListEmailVerificationAttempts200['results']>(
    props?: Utils.UseQueryOptions<ListEmailVerificationAttempts200, unknown, TSelect> &
      Utils.Params<ListEmailVerificationAttemptsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmailVerificationAttempt>(
    id: string | null,
    props?: Utils.UseQueryOptions<EmailVerificationAttempt, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmailVerificationAttemptCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EmailVerificationSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/email_verifications/`

  // plugin buildResourceMethods
  static list = G.listEmailVerifications

  static create = G.createEmailVerification

  static retrieve = G.retrieveEmailVerification

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/users/email_verifications/']

  static listQ = <TSelect = ListEmailVerifications200['results']>(
    props?: Utils.UseQueryOptions<ListEmailVerifications200, unknown, TSelect> &
      Utils.Params<ListEmailVerificationsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmailVerification>(
    id: string | null,
    props?: Utils.UseQueryOptions<EmailVerification, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmailVerificationCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class UserSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/`

  // plugin buildResourceMethods
  static list = G.listUsers

  static create = G.createUser

  static retrieve = G.retrieveUser

  static update = G.partialUpdateUser

  // plugin addResourceDependencies
  static downstream = [
    '/admin/business_notes/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/attachments/internal_notes/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/cards/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/budgeting/get_project_budget_cost/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/get_auth_request_link/',
    '/onboarding/business_profiles/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
    '/users/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/users/']

  static listQ = <TSelect = ListUsers200['results']>(
    props?: Utils.UseQueryOptions<ListUsers200, unknown, TSelect> & Utils.Params<ListUsersParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = User>(
    id: string | null,
    props?: Utils.UseQueryOptions<User, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: UserCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<UserPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class CardSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/cards/`

  // plugin buildResourceMethods
  static list = G.listCards

  static retrieve = G.retrieveCard

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/banking/cards/']

  static listQ = <TSelect = ListCards200['results']>(
    props?: Utils.UseQueryOptions<ListCards200, unknown, TSelect> & Utils.Params<ListCardsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Card>(
    id: string | null,
    props?: Utils.UseQueryOptions<Card, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class MicrodepositRequirementSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/microdeposit_requirements/`

  // plugin buildResourceMethods
  static list = G.listMicrodepositRequirements

  static retrieve = G.retrieveMicrodepositRequirement

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/banking/microdeposit_requirements/']

  static listQ = <TSelect = ListMicrodepositRequirements200['results']>(
    props?: Utils.UseQueryOptions<ListMicrodepositRequirements200, unknown, TSelect> &
      Utils.Params<ListMicrodepositRequirementsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = MicrodepositRequirement>(
    id: string | null,
    props?: Utils.UseQueryOptions<MicrodepositRequirement, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class TransactionAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/transaction_accounts/`

  // plugin buildResourceMethods
  static list = G.listTransactionAccounts

  static retrieve = G.retrieveTransactionAccount

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/banking/transaction_accounts/']

  static listQ = <TSelect = ListTransactionAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListTransactionAccounts200, unknown, TSelect> &
      Utils.Params<ListTransactionAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = TransactionAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<TransactionAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetBankNameSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/get_bank_name/`

  // plugin buildResourceMethods
  static get = G.getBankName

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/banking/get_bank_name/']

  static getQ = <TSelect = GetBankNameViewOutput>(
    props: Utils.UseQueryOptions<GetBankNameViewOutput, unknown, TSelect> & {
      params: GetBankNameParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetStatementPDFSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/get_statement_pdf/`

  // plugin buildResourceMethods
  static get = G.getStatementPDF

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/banking/get_statement_pdf/']

  static getQ = <TSelect = Blob>(
    props: Utils.UseQueryOptions<Blob, unknown, TSelect> & { params: GetStatementPDFParams },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class BankAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/bank_accounts/`

  // plugin buildResourceMethods
  static list = G.listBankAccounts

  static create = G.createBankAccount

  static retrieve = G.retrieveBankAccount

  static update = G.partialUpdateBankAccount

  static delete = G.destroyBankAccount

  static archive_restrictions = G.getBankAccountArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/banking/bank_accounts/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_bank_name/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/external_payment_sources/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/billing/payment_sources/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/banking/bank_accounts/']

  static listQ = <TSelect = ListBankAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListBankAccounts200, unknown, TSelect> &
      Utils.Params<ListBankAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BankAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<BankAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = ArchiveRestriction>(
    id: string,
    props?: Utils.UseQueryOptions<ArchiveRestriction, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BankAccountCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<BankAccountPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ControlledPayeeBankAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/controlled_payee_bank_accounts/`

  // plugin buildResourceMethods
  static list = G.listControlledPayeeBankAccounts

  static create = G.createControlledPayeeBankAccount

  static retrieve = G.retrieveControlledPayeeBankAccount

  static update = G.partialUpdateControlledPayeeBankAccount

  static archive_restrictions = G.getControlledPayeeBankAccountArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = [
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/banking/bank_accounts/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_bank_name/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/external_payment_sources/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/billing/payment_sources/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/banking/controlled_payee_bank_accounts/']

  static listQ = <TSelect = ListControlledPayeeBankAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListControlledPayeeBankAccounts200, unknown, TSelect> &
      Utils.Params<ListControlledPayeeBankAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ControlledPayeeBankAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<ControlledPayeeBankAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = ArchiveRestriction>(
    id: string,
    props?: Utils.UseQueryOptions<ArchiveRestriction, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ControlledPayeeBankAccountCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ControlledPayeeBankAccountPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class PayeeBankAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/payee_bank_accounts/`

  // plugin buildResourceMethods
  static list = G.listPayeeBankAccounts

  static retrieve = G.retrievePayeeBankAccount

  static archive_restrictions = G.getPayeeBankAccountArchiveRestrictions

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/banking/payee_bank_accounts/']

  static listQ = <TSelect = ListPayeeBankAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListPayeeBankAccounts200, unknown, TSelect> &
      Utils.Params<ListPayeeBankAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PayeeBankAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<PayeeBankAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static archive_restrictionsQ = <TSelect = ArchiveRestriction>(
    id: string,
    props?: Utils.UseQueryOptions<ArchiveRestriction, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.archive_restrictions(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'archive_restrictions']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class CashBackPaymentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/fees/cash_back_payments/`

  // plugin buildResourceMethods
  static list = G.listCashBackPayments

  static retrieve = G.retrieveCashBackPayment

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/fees/cash_back_payments/']

  static listQ = <TSelect = ListCashBackPayments200['results']>(
    props?: Utils.UseQueryOptions<ListCashBackPayments200, unknown, TSelect> &
      Utils.Params<ListCashBackPaymentsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CashBackPayment>(
    id: string | null,
    props?: Utils.UseQueryOptions<CashBackPayment, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class YieldPaymentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/fees/yield_payments/`

  // plugin buildResourceMethods
  static list = G.listYieldPayments

  static retrieve = G.retrieveYieldPayment

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/fees/yield_payments/']

  static listQ = <TSelect = ListYieldPayments200['results']>(
    props?: Utils.UseQueryOptions<ListYieldPayments200, unknown, TSelect> &
      Utils.Params<ListYieldPaymentsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = YieldPayment>(
    id: string | null,
    props?: Utils.UseQueryOptions<YieldPayment, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetProjectBudgetCostSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/budgeting/get_project_budget_cost/`

  // plugin buildResourceMethods
  static get = G.getProjectBudgetCost

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/budgeting/get_project_budget_cost/']

  static getQ = <TSelect = GetProjectBudgetCostViewOutput>(
    props: Utils.UseQueryOptions<GetProjectBudgetCostViewOutput, unknown, TSelect> & {
      params: GetProjectBudgetCostParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class BudgetConfigSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/budgeting/budget_configs/`

  // plugin buildResourceMethods
  static list = G.listBudgetConfigs

  static create = G.createBudgetConfig

  static retrieve = G.retrieveBudgetConfig

  static update = G.partialUpdateBudgetConfig

  static singleton = G.retrieveSingletonBudgetConfig

  // plugin addResourceDependencies
  static downstream = ['/budgeting/budget_configs/']

  // plugin addQuerySpecs
  static key = () => ['/budgeting/budget_configs/']

  static listQ = <TSelect = ListBudgetConfigs200['results']>(
    props?: Utils.UseQueryOptions<ListBudgetConfigs200, unknown, TSelect> &
      Utils.Params<ListBudgetConfigsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BudgetConfig>(
    id: string | null,
    props?: Utils.UseQueryOptions<BudgetConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = BudgetConfig>(
    props?: Utils.UseQueryOptions<BudgetConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BudgetConfigCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<BudgetConfigPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ProjectBudgetItemSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/budgeting/project_budget_items/`

  // plugin buildResourceMethods
  static list = G.listProjectBudgetItems

  static create = G.createProjectBudgetItem

  static retrieve = G.retrieveProjectBudgetItem

  static update = G.partialUpdateProjectBudgetItem

  static delete = G.destroyProjectBudgetItem

  // plugin addResourceDependencies
  static downstream = [
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/budgeting/project_budget_items/']

  static listQ = <TSelect = ListProjectBudgetItems200['results']>(
    props?: Utils.UseQueryOptions<ListProjectBudgetItems200, unknown, TSelect> &
      Utils.Params<ListProjectBudgetItemsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectBudgetItem>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectBudgetItem, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ProjectBudgetItemCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ProjectBudgetItemPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ProjectBudgetSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/budgeting/project_budgets/`

  // plugin buildResourceMethods
  static list = G.listProjectBudgets

  static create = G.createProjectBudget

  static retrieve = G.retrieveProjectBudget

  static update = G.partialUpdateProjectBudget

  // plugin addResourceDependencies
  static downstream = [
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/budgeting/project_budgets/']

  static listQ = <TSelect = ListProjectBudgets200['results']>(
    props?: Utils.UseQueryOptions<ListProjectBudgets200, unknown, TSelect> &
      Utils.Params<ListProjectBudgetsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectBudget>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectBudget, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ProjectBudgetCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ProjectBudgetPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class TaskSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/tasks/`

  // plugin buildResourceMethods
  static list = G.listTasks

  static retrieve = G.retrieveTask

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/tasks/']

  static listQ = <TSelect = ListTasks200['results']>(
    props?: Utils.UseQueryOptions<ListTasks200, unknown, TSelect> & Utils.Params<ListTasksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Task>(
    id: string | null,
    props?: Utils.UseQueryOptions<Task, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class FileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/files/`

  // plugin buildResourceMethods
  static download = G.retrieveFileDownload

  static create = G.createFile

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/get_invoice_file/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/project_expenses/',
    '/compliance/compliance_documents/',
    '/estimates/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/employee_profiles/',
    '/projects/contract_files/',
    '/projects/contracts/',
    '/projects/logos/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/files/']

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: FileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BusinessProfileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/business_profiles/`

  // plugin buildResourceMethods
  static list = G.listBusinessProfiles

  static create = G.createBusinessProfile

  static retrieve = G.retrieveBusinessProfile

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/customer_summaries/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/businesses/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/estimates/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/page_resources/bill_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/renofi_ads/',
    '/projects/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/onboarding/business_profiles/']

  static listQ = <TSelect = ListBusinessProfiles200['results']>(
    props?: Utils.UseQueryOptions<ListBusinessProfiles200, unknown, TSelect> &
      Utils.Params<ListBusinessProfilesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BusinessProfile>(
    id: string | null,
    props?: Utils.UseQueryOptions<BusinessProfile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BusinessProfileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class OnboardingFlowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/onboarding_flows/`

  // plugin buildResourceMethods
  static singleton = G.retrieveSingletonOnboardingFlow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/onboarding/onboarding_flows/']

  static singletonQ = <TSelect = OnboardingFlow>(
    props?: Utils.UseQueryOptions<OnboardingFlow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class WhoAmISpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/whoami/`

  // plugin buildResourceMethods
  static get = G.whoAmI

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/onboarding/whoami/']

  static getQ = <TSelect = WhoAmIViewOutput>(
    props?: Utils.UseQueryOptions<WhoAmIViewOutput, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class EmployeeProfileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/employee_profiles/`

  // plugin buildResourceMethods
  static list = G.listEmployeeProfiles

  static create = G.createEmployeeProfile

  static retrieve = G.retrieveEmployeeProfile

  static update = G.partialUpdateEmployeeProfile

  static delete = G.destroyEmployeeProfile

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/customer_summaries/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/businesses/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/estimates/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/page_resources/bill_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/renofi_ads/',
    '/projects/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/onboarding/employee_profiles/']

  static listQ = <TSelect = ListEmployeeProfiles200['results']>(
    props?: Utils.UseQueryOptions<ListEmployeeProfiles200, unknown, TSelect> &
      Utils.Params<ListEmployeeProfilesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmployeeProfile>(
    id: string | null,
    props?: Utils.UseQueryOptions<EmployeeProfile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmployeeProfileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<EmployeeProfilePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class OnboardingSurveySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/onboarding_surveys/`

  // plugin buildResourceMethods
  static list = G.listOnboardingSurveys

  static create = G.createOnboardingSurvey

  static retrieve = G.retrieveOnboardingSurvey

  static update = G.partialUpdateOnboardingSurvey

  // plugin addResourceDependencies
  static downstream = ['/onboarding/onboarding_surveys/']

  // plugin addQuerySpecs
  static key = () => ['/onboarding/onboarding_surveys/']

  static listQ = <TSelect = ListOnboardingSurveys200['results']>(
    props?: Utils.UseQueryOptions<ListOnboardingSurveys200, unknown, TSelect> &
      Utils.Params<ListOnboardingSurveysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = OnboardingSurvey>(
    id: string | null,
    props?: Utils.UseQueryOptions<OnboardingSurvey, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: OnboardingSurveyCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<OnboardingSurveyPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class TermsOfServiceAcceptanceSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/terms_of_service_acceptances/`

  // plugin buildResourceMethods
  static list = G.listTermsOfServiceAcceptances

  static create = G.createTermsOfServiceAcceptance

  static retrieve = G.retrieveTermsOfServiceAcceptance

  // plugin addResourceDependencies
  static downstream = ['/onboarding/terms_of_service_acceptances/']

  // plugin addQuerySpecs
  static key = () => ['/onboarding/terms_of_service_acceptances/']

  static listQ = <TSelect = ListTermsOfServiceAcceptances200['results']>(
    props?: Utils.UseQueryOptions<ListTermsOfServiceAcceptances200, unknown, TSelect> &
      Utils.Params<ListTermsOfServiceAcceptancesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = TermsOfServiceAcceptance>(
    id: string | null,
    props?: Utils.UseQueryOptions<TermsOfServiceAcceptance, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: TermsOfServiceAcceptanceCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class TermsOfServiceSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/terms_of_services/`

  // plugin buildResourceMethods
  static list = G.listTermsOfServices

  static retrieve = G.retrieveTermsOfService

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/onboarding/terms_of_services/']

  static listQ = <TSelect = ListTermsOfServices200['results']>(
    props?: Utils.UseQueryOptions<ListTermsOfServices200, unknown, TSelect> &
      Utils.Params<ListTermsOfServicesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = TermsOfService>(
    id: string | null,
    props?: Utils.UseQueryOptions<TermsOfService, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ComplianceDocumentEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (complianceDocumentId: string) =>
    `/compliance/compliance_documents/${complianceDocumentId}/files/`

  // plugin buildResourceMethods
  static list = G.listComplianceDocumentEmbeddedFiles

  static retrieve = G.retrieveComplianceDocumentEmbeddedFile

  static download = G.retrieveComplianceDocumentEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (complianceDocumentId: string) => [
    '/compliance/compliance_documents/',
    `${complianceDocumentId}`,
    '/files/',
  ]

  static listQ = <TSelect = ListComplianceDocumentEmbeddedFiles200['results']>(
    complianceDocumentId: string,
    props?: Utils.UseQueryOptions<ListComplianceDocumentEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListComplianceDocumentEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(complianceDocumentId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(complianceDocumentId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    complianceDocumentId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(complianceDocumentId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(complianceDocumentId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    complianceDocumentId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(complianceDocumentId, id)
    const queryKey: Utils.QueryKey = [...this.key(complianceDocumentId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class ComplianceDocumentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/compliance_documents/`

  // plugin buildResourceMethods
  static list = G.listComplianceDocuments

  static create = G.createComplianceDocument

  static retrieve = G.retrieveComplianceDocument

  static delete = G.destroyComplianceDocument

  // plugin addResourceDependencies
  static downstream = [
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_status_summarys/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/compliance/compliance_documents/']

  static listQ = <TSelect = ListComplianceDocuments200['results']>(
    props?: Utils.UseQueryOptions<ListComplianceDocuments200, unknown, TSelect> &
      Utils.Params<ListComplianceDocumentsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ComplianceDocument>(
    id: string | null,
    props?: Utils.UseQueryOptions<ComplianceDocument, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ComplianceDocumentCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ComplianceSettingsListSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/compliance_settings_lists/`

  // plugin buildResourceMethods
  static list = G.listComplianceSettingsLists

  static singleton = G.retrieveSingletonComplianceSettingsList

  static retrieve = G.retrieveComplianceSettingsList

  static update = G.partialUpdateComplianceSettingsList

  // plugin addResourceDependencies
  static downstream = [
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/requirement_lists/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/compliance/compliance_settings_lists/']

  static listQ = <TSelect = ListComplianceSettingsLists200['results']>(
    props?: Utils.UseQueryOptions<ListComplianceSettingsLists200, unknown, TSelect> &
      Utils.Params<ListComplianceSettingsListsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = ComplianceSettingsList>(
    props?: Utils.UseQueryOptions<ComplianceSettingsList, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  static retrieveQ = <TSelect = ComplianceSettingsList>(
    id: string | null,
    props?: Utils.UseQueryOptions<ComplianceSettingsList, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ComplianceSettingsListPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ComplianceStatusSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/compliance_status_summarys/`

  // plugin buildResourceMethods
  static list = G.listComplianceStatusSummarys

  static retrieve = G.retrieveComplianceStatusSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/compliance/compliance_status_summarys/']

  static listQ = <TSelect = ListComplianceStatusSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListComplianceStatusSummarys200, unknown, TSelect> &
      Utils.Params<ListComplianceStatusSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ComplianceStatusSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<ComplianceStatusSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class PayeeComplianceRequirementSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/payee_compliance_requirements/`

  // plugin buildResourceMethods
  static list = G.listPayeeComplianceRequirements

  static retrieve = G.retrievePayeeComplianceRequirement

  static update = G.partialUpdatePayeeComplianceRequirement

  // plugin addResourceDependencies
  static downstream = [
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/compliance/payee_compliance_requirements/']

  static listQ = <TSelect = ListPayeeComplianceRequirements200['results']>(
    props?: Utils.UseQueryOptions<ListPayeeComplianceRequirements200, unknown, TSelect> &
      Utils.Params<ListPayeeComplianceRequirementsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = PayeeComplianceRequirement>(
    id: string | null,
    props?: Utils.UseQueryOptions<PayeeComplianceRequirement, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<PayeeComplianceRequirementPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class RequirementListSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/requirement_lists/`

  // plugin buildResourceMethods
  static list = G.listRequirementLists

  static retrieve = G.retrieveRequirementList

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/compliance/requirement_lists/']

  static listQ = <TSelect = ListRequirementLists200['results']>(
    props?: Utils.UseQueryOptions<ListRequirementLists200, unknown, TSelect> &
      Utils.Params<ListRequirementListsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = RequirementList>(
    id: string | null,
    props?: Utils.UseQueryOptions<RequirementList, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetCombinedComplianceSettingsListAsPayeeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/get_combined_compliance_settings_list_as_payee/`

  // plugin buildResourceMethods
  static get = G.getCombinedComplianceSettingsListAsPayee

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/compliance/get_combined_compliance_settings_list_as_payee/']

  static getQ = <TSelect = GetCombinedComplianceSettingsListAsPayeeViewOutput>(
    props: Utils.UseQueryOptions<
      GetCombinedComplianceSettingsListAsPayeeViewOutput,
      unknown,
      TSelect
    > & { params: GetCombinedComplianceSettingsListAsPayeeParams },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetComplianceSettingsListForPayeeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/get_compliance_settings_list_for_payee/`

  // plugin buildResourceMethods
  static get = G.getComplianceSettingsListForPayee

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/compliance/get_compliance_settings_list_for_payee/']

  static getQ = <TSelect = GetComplianceSettingsListForPayeeViewOutput>(
    props: Utils.UseQueryOptions<GetComplianceSettingsListForPayeeViewOutput, unknown, TSelect> & {
      params: GetComplianceSettingsListForPayeeParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class ComplianceSettingsListPayeeOverrideSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/compliance/compliance_settings_list_payee_overrides/`

  // plugin buildResourceMethods
  static list = G.listComplianceSettingsListPayeeOverrides

  static create = G.createComplianceSettingsListPayeeOverride

  static retrieve = G.retrieveComplianceSettingsListPayeeOverride

  static update = G.partialUpdateComplianceSettingsListPayeeOverride

  static delete = G.destroyComplianceSettingsListPayeeOverride

  // plugin addResourceDependencies
  static downstream = [
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/requirement_lists/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/compliance/compliance_settings_list_payee_overrides/']

  static listQ = <TSelect = ListComplianceSettingsListPayeeOverrides200['results']>(
    props?: Utils.UseQueryOptions<ListComplianceSettingsListPayeeOverrides200, unknown, TSelect> &
      Utils.Params<ListComplianceSettingsListPayeeOverridesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ComplianceSettingsListPayeeOverride>(
    id: string | null,
    props?: Utils.UseQueryOptions<ComplianceSettingsListPayeeOverride, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ComplianceSettingsListPayeeOverrideCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<ComplianceSettingsListPayeeOverridePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EstimateApprovalSignatureSpec {
  // plugin addBaseUrl
  static baseUrl = (estimateId: string) => `/estimates/${estimateId}/approval_signature/`

  // plugin buildResourceMethods
  static list = G.listEstimateApprovalSignatures

  static retrieve = G.retrieveEstimateApprovalSignature

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (estimateId: string) => ['/estimates/', `${estimateId}`, '/approval_signature/']

  static listQ = <TSelect = ListEstimateApprovalSignatures200['results']>(
    estimateId: string,
    props?: Utils.UseQueryOptions<ListEstimateApprovalSignatures200, unknown, TSelect> &
      Utils.Params<ListEstimateApprovalSignaturesParams>,
  ) => {
    const queryFn = () => this.list(estimateId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(estimateId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimateApprovalSignature>(
    estimateId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EstimateApprovalSignature, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(estimateId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(estimateId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class EstimateEmailSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/estimate_emails/`

  // plugin buildResourceMethods
  static list = G.listEstimateEmails

  static retrieve = G.retrieveEstimateEmail

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/estimates/estimate_emails/']

  static listQ = <TSelect = ListEstimateEmails200['results']>(
    props?: Utils.UseQueryOptions<ListEstimateEmails200, unknown, TSelect> &
      Utils.Params<ListEstimateEmailsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimateEmail>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimateEmail, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class EstimateEmailViewSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/estimate_email_views/`

  // plugin buildResourceMethods
  static list = G.listEstimateEmailViews

  static retrieve = G.retrieveEstimateEmailView

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/estimates/estimate_email_views/']

  static listQ = <TSelect = ListEstimateEmailViews200['results']>(
    props?: Utils.UseQueryOptions<ListEstimateEmailViews200, unknown, TSelect> &
      Utils.Params<ListEstimateEmailViewsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimateEmailView>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimateEmailView, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class EstimateEmbeddedFileSpec {
  // plugin addBaseUrl
  static baseUrl = (estimateId: string) => `/estimates/${estimateId}/files/`

  // plugin buildResourceMethods
  static list = G.listEstimateEmbeddedFiles

  static create = G.createEstimateEmbeddedFile

  static retrieve = G.retrieveEstimateEmbeddedFile

  static download = G.retrieveEstimateEmbeddedFileDownload

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (estimateId: string) => ['/estimates/', `${estimateId}`, '/files/']

  static listQ = <TSelect = ListEstimateEmbeddedFiles200['results']>(
    estimateId: string,
    props?: Utils.UseQueryOptions<ListEstimateEmbeddedFiles200, unknown, TSelect> &
      Utils.Params<ListEstimateEmbeddedFilesParams>,
  ) => {
    const queryFn = () => this.list(estimateId, props?.params)
    const queryKey: Utils.QueryKey = [...this.key(estimateId), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EmbeddedFile>(
    estimateId: string,
    id: string | null,
    props?: Utils.UseQueryOptions<EmbeddedFile, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(estimateId, id as string)
    const queryKey: Utils.QueryKey = [...this.key(estimateId), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    estimateId: string,
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(estimateId, id)
    const queryKey: Utils.QueryKey = [...this.key(estimateId), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: [estimateId: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EmbeddedFileCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EstimatePageVisitSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/estimate_page_visits/`

  // plugin buildResourceMethods
  static list = G.listEstimatePageVisits

  static create = G.createEstimatePageVisit

  static retrieve = G.retrieveEstimatePageVisit

  // plugin addResourceDependencies
  static downstream = ['/estimates/estimate_page_visits/']

  // plugin addQuerySpecs
  static key = () => ['/estimates/estimate_page_visits/']

  static listQ = <TSelect = ListEstimatePageVisits200['results']>(
    props?: Utils.UseQueryOptions<ListEstimatePageVisits200, unknown, TSelect> &
      Utils.Params<ListEstimatePageVisitsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimatePaymentPageVisit>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimatePaymentPageVisit, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EstimatePaymentPageVisitCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EstimateSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/`

  // plugin buildResourceMethods
  static list = G.listEstimates

  static create = G.createEstimate

  static retrieve = G.retrieveEstimate

  static update = G.partialUpdateEstimate

  static delete = G.destroyEstimate

  static pdf = G.retrieveEstimatePdf

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/notes/',
    '/banking/get_statement_pdf/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_retainage_values/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_items/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/compliance/compliance_documents/',
    '/estimates/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/page_resources/bill_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/retainage_releases/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/estimates/']

  static listQ = <TSelect = ListEstimates200['results']>(
    props?: Utils.UseQueryOptions<ListEstimates200, unknown, TSelect> &
      Utils.Params<ListEstimatesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Estimate>(
    id: string | null,
    props?: Utils.UseQueryOptions<Estimate, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static pdfQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.pdf(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'pdf']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EstimateCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<EstimatePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GetEstimateCoverPagePdfPreviewSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/get_estimate_cover_page_pdf_preview/`

  // plugin buildResourceMethods
  static get = G.getEstimateCoverPagePdfPreview

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/estimates/get_estimate_cover_page_pdf_preview/']

  static getQ = <TSelect = Blob>(
    props: Utils.UseQueryOptions<Blob, unknown, TSelect> & {
      params: GetEstimateCoverPagePdfPreviewParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetEstimateInputFromFileSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/get_estimate_input_from_file/`

  // plugin buildResourceMethods
  static get = G.getEstimateInputFromFile

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/estimates/get_estimate_input_from_file/']

  static getQ = <TSelect = GetEstimateInputFromFileViewOutput>(
    props: Utils.UseQueryOptions<GetEstimateInputFromFileViewOutput, unknown, TSelect> & {
      params: GetEstimateInputFromFileParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetNextEstimateNumberSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/get_next_estimate_number/`

  // plugin buildResourceMethods
  static get = G.getNextEstimateNumber

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/estimates/get_next_estimate_number/']

  static getQ = <TSelect = GetNextEstimateNumberOutput>(
    props: Utils.UseQueryOptions<GetNextEstimateNumberOutput, unknown, TSelect> & {
      params: GetNextEstimateNumberParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class EstimateConfigSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/estimate_configs/`

  // plugin buildResourceMethods
  static list = G.listEstimateConfigs

  static create = G.createEstimateConfig

  static retrieve = G.retrieveEstimateConfig

  static update = G.partialUpdateEstimateConfig

  static singleton = G.retrieveSingletonEstimateConfig

  // plugin addResourceDependencies
  static downstream = ['/estimates/estimate_configs/']

  // plugin addQuerySpecs
  static key = () => ['/estimates/estimate_configs/']

  static listQ = <TSelect = ListEstimateConfigs200['results']>(
    props?: Utils.UseQueryOptions<ListEstimateConfigs200, unknown, TSelect> &
      Utils.Params<ListEstimateConfigsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimateConfig>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimateConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = EstimateConfig>(
    props?: Utils.UseQueryOptions<EstimateConfig, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EstimateConfigCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<EstimateConfigPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EstimateCoverPagePhotoSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/estimate_cover_page_photos/`

  // plugin buildResourceMethods
  static list = G.listEstimateCoverPagePhotos

  static create = G.createEstimateCoverPagePhoto

  static retrieve = G.retrieveEstimateCoverPagePhoto

  static delete = G.destroyEstimateCoverPagePhoto

  static download = G.downloadEstimateCoverPagePhoto

  // plugin addResourceDependencies
  static downstream = [
    '/estimates/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/estimates/estimate_cover_page_photos/']

  static listQ = <TSelect = ListEstimateCoverPagePhotos200['results']>(
    props?: Utils.UseQueryOptions<ListEstimateCoverPagePhotos200, unknown, TSelect> &
      Utils.Params<ListEstimateCoverPagePhotosParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimateCoverPagePhoto>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimateCoverPagePhoto, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EstimateCoverPagePhotoCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EstimateCoverPageSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/estimate_cover_pages/`

  // plugin buildResourceMethods
  static list = G.listEstimateCoverPages

  static create = G.createEstimateCoverPage

  static retrieve = G.retrieveEstimateCoverPage

  static update = G.partialUpdateEstimateCoverPage

  static delete = G.destroyEstimateCoverPage

  static download = G.downloadEstimateCoverPage

  // plugin addResourceDependencies
  static downstream = [
    '/estimates/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/estimates/estimate_cover_pages/']

  static listQ = <TSelect = ListEstimateCoverPages200['results']>(
    props?: Utils.UseQueryOptions<ListEstimateCoverPages200, unknown, TSelect> &
      Utils.Params<ListEstimateCoverPagesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimateCoverPage>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimateCoverPage, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static downloadQ = <TSelect = Blob>(
    id: string,
    props?: Utils.UseQueryOptions<Blob, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.download(id)
    const queryKey: Utils.QueryKey = [...this.key(), id, 'download']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: EstimateCoverPageCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<EstimateCoverPagePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EstimatesAsPayeePageSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/estimates_as_payee_page_summarys/`

  // plugin buildResourceMethods
  static list = G.listEstimatesAsPayeePageSummarys

  static retrieve = G.retrieveEstimatesAsPayeePageSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/estimates/estimates_as_payee_page_summarys/']

  static listQ = <TSelect = ListEstimatesAsPayeePageSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListEstimatesAsPayeePageSummarys200, unknown, TSelect> &
      Utils.Params<ListEstimatesAsPayeePageSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimatesAsPayeePageSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimatesAsPayeePageSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class AccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/accounts/`

  // plugin buildResourceMethods
  static list = G.listAccounts

  static create = G.createAccount

  static retrieve = G.retrieveAccount

  static update = G.partialUpdateAccount

  // plugin addResourceDependencies
  static downstream = [
    '/admin/accounts/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin_resources/customer_summaries/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/billing/bills/',
    '/billing/get_next_invoice_number/',
    '/billing/invoices/',
    '/billing/project_expenses/',
    '/page_resources/bill_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/projects/cost_codes/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/agave/accounts/']

  static listQ = <TSelect = ListAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListAccounts200, unknown, TSelect> &
      Utils.Params<ListAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Account>(
    id: string | null,
    props?: Utils.UseQueryOptions<Account, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: AccountCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<AccountPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InvoiceOutboundSyncV2Spec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/invoice_outbound_syncs_v2/`

  // plugin buildResourceMethods
  static list = G.listInvoiceOutboundSyncV2s

  static retrieve = G.retrieveInvoiceOutboundSyncV2

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/invoice_outbound_syncs_v2/']

  static listQ = <TSelect = ListInvoiceOutboundSyncV2s200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceOutboundSyncV2s200, unknown, TSelect> &
      Utils.Params<ListInvoiceOutboundSyncV2sParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceOutboundSyncV2>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceOutboundSyncV2, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetLinkTokenSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/get_link_token/`

  // plugin buildResourceMethods
  static get = G.getLinkToken

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/get_link_token/']

  static getQ = <TSelect = GetLinkTokenOutput>(
    props?: Utils.UseQueryOptions<GetLinkTokenOutput, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetProjectImportRequestSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/get_project_import_request/`

  // plugin buildResourceMethods
  static get = G.getProjectImportRequest

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/get_project_import_request/']

  static getQ = <TSelect = GetProjectImportRequestViewOutput>(
    props?: Utils.UseQueryOptions<GetProjectImportRequestViewOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetUpdatedTokenStatusSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/get_updated_token_status/`

  // plugin buildResourceMethods
  static get = G.getUpdatedTokenStatus

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/get_updated_token_status/']

  static getQ = <TSelect = GetUpdatedTokenStatusOutput>(
    props?: Utils.UseQueryOptions<GetUpdatedTokenStatusOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class QBPullRequestSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/qb_pull_requests/`

  // plugin buildResourceMethods
  static list = G.listQBPullRequests

  static retrieve = G.retrieveQBPullRequest

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/qb_pull_requests/']

  static listQ = <TSelect = ListQBPullRequests200['results']>(
    props?: Utils.UseQueryOptions<ListQBPullRequests200, unknown, TSelect> &
      Utils.Params<ListQBPullRequestsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = QBPullRequest>(
    id: string | null,
    props?: Utils.UseQueryOptions<QBPullRequest, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class QBPushRequestSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/qb_push_requests/`

  // plugin buildResourceMethods
  static list = G.listQBPushRequests

  static retrieve = G.retrieveQBPushRequest

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/qb_push_requests/']

  static listQ = <TSelect = ListQBPushRequests200['results']>(
    props?: Utils.UseQueryOptions<ListQBPushRequests200, unknown, TSelect> &
      Utils.Params<ListQBPushRequestsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = QBPushRequest>(
    id: string | null,
    props?: Utils.UseQueryOptions<QBPushRequest, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class QboLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/qbo_links/`

  // plugin buildResourceMethods
  static list = G.listQboLinks

  static retrieve = G.retrieveQboLink

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/qbo_links/']

  static listQ = <TSelect = ListQboLinks200['results']>(
    props?: Utils.UseQueryOptions<ListQboLinks200, unknown, TSelect> &
      Utils.Params<ListQboLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = QboLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<QboLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class RawQboLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/agave/raw_qbo_links/`

  // plugin buildResourceMethods
  static list = G.listRawQboLinks

  static retrieve = G.retrieveRawQboLink

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/agave/raw_qbo_links/']

  static listQ = <TSelect = ListRawQboLinks200['results']>(
    props?: Utils.UseQueryOptions<ListRawQboLinks200, unknown, TSelect> &
      Utils.Params<ListRawQboLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = RawQboLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<RawQboLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class BillListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/bill_list_rows/`

  // plugin buildResourceMethods
  static list = G.listBillListRows

  static retrieve = G.retrieveBillListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/bill_list_rows/']

  static listQ = <TSelect = ListBillListRows200['results']>(
    props?: Utils.UseQueryOptions<ListBillListRows200, unknown, TSelect> &
      Utils.Params<ListBillListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BillListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<BillListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class CardTransactionListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/card_transaction_list_rows/`

  // plugin buildResourceMethods
  static list = G.listCardTransactionListRows

  static retrieve = G.retrieveCardTransactionListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/card_transaction_list_rows/']

  static listQ = <TSelect = ListCardTransactionListRows200['results']>(
    props?: Utils.UseQueryOptions<ListCardTransactionListRows200, unknown, TSelect> &
      Utils.Params<ListCardTransactionListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CardTransactionListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<CardTransactionListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ClientListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/client_list_rows/`

  // plugin buildResourceMethods
  static list = G.listClientListRows

  static retrieve = G.retrieveClientListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/client_list_rows/']

  static listQ = <TSelect = ListClientListRows200['results']>(
    props?: Utils.UseQueryOptions<ListClientListRows200, unknown, TSelect> &
      Utils.Params<ListClientListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ClientListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<ClientListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ExpenseListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/expense_list_rows/`

  // plugin buildResourceMethods
  static list = G.listExpenseListRows

  static retrieve = G.retrieveExpenseListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/expense_list_rows/']

  static listQ = <TSelect = ListExpenseListRows200['results']>(
    props?: Utils.UseQueryOptions<ListExpenseListRows200, unknown, TSelect> &
      Utils.Params<ListExpenseListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ExpenseListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<ExpenseListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GCSubcontractInlineListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/gc_subcontract_inline_line_list_rows/`

  // plugin buildResourceMethods
  static list = G.listGCSubcontractInlineListRows

  static retrieve = G.retrieveGCSubcontractInlineListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/gc_subcontract_inline_line_list_rows/']

  static listQ = <TSelect = ListGCSubcontractInlineListRows200['results']>(
    props?: Utils.UseQueryOptions<ListGCSubcontractInlineListRows200, unknown, TSelect> &
      Utils.Params<ListGCSubcontractInlineListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = GCSubcontractInlineListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<GCSubcontractInlineListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoiceListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/invoice_list_rows/`

  // plugin buildResourceMethods
  static list = G.listInvoiceListRows

  static retrieve = G.retrieveInvoiceListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/invoice_list_rows/']

  static listQ = <TSelect = ListInvoiceListRows200['results']>(
    props?: Utils.UseQueryOptions<ListInvoiceListRows200, unknown, TSelect> &
      Utils.Params<ListInvoiceListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoiceListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoiceListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoicePaymentFeeListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/invoice_payment_fee_list_rows/`

  // plugin buildResourceMethods
  static list = G.listInvoicePaymentFeeListRows

  static retrieve = G.retrieveInvoicePaymentFeeListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/invoice_payment_fee_list_rows/']

  static listQ = <TSelect = ListInvoicePaymentFeeListRows200['results']>(
    props?: Utils.UseQueryOptions<ListInvoicePaymentFeeListRows200, unknown, TSelect> &
      Utils.Params<ListInvoicePaymentFeeListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoicePaymentFeeListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoicePaymentFeeListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ProjectListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/project_list_rows/`

  // plugin buildResourceMethods
  static list = G.listProjectListRows

  static retrieve = G.retrieveProjectListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/project_list_rows/']

  static listQ = <TSelect = ListProjectListRows200['results']>(
    props?: Utils.UseQueryOptions<ListProjectListRows200, unknown, TSelect> &
      Utils.Params<ListProjectListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class VendorListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/vendor_list_rows/`

  // plugin buildResourceMethods
  static list = G.listVendorListRows

  static retrieve = G.retrieveVendorListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/vendor_list_rows/']

  static listQ = <TSelect = ListVendorListRows200['results']>(
    props?: Utils.UseQueryOptions<ListVendorListRows200, unknown, TSelect> &
      Utils.Params<ListVendorListRowsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = VendorListRow>(
    id: string | null,
    props?: Utils.UseQueryOptions<VendorListRow, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class BillsPageSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/bills_page_summarys/`

  // plugin buildResourceMethods
  static list = G.listBillsPageSummarys

  static retrieve = G.retrieveBillsPageSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/bills_page_summarys/']

  static listQ = <TSelect = ListBillsPageSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListBillsPageSummarys200, unknown, TSelect> &
      Utils.Params<ListBillsPageSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BillsPageSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<BillsPageSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class EstimatesPageSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/estimates_page_summarys/`

  // plugin buildResourceMethods
  static list = G.listEstimatesPageSummarys

  static retrieve = G.retrieveEstimatesPageSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/estimates_page_summarys/']

  static listQ = <TSelect = ListEstimatesPageSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListEstimatesPageSummarys200, unknown, TSelect> &
      Utils.Params<ListEstimatesPageSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = EstimatesPageSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<EstimatesPageSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class InvoicesPageSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/invoices_page_summarys/`

  // plugin buildResourceMethods
  static list = G.listInvoicesPageSummarys

  static retrieve = G.retrieveInvoicesPageSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/invoices_page_summarys/']

  static listQ = <TSelect = ListInvoicesPageSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListInvoicesPageSummarys200, unknown, TSelect> &
      Utils.Params<ListInvoicesPageSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = InvoicesPageSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<InvoicesPageSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ProjectsPageSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/page_resources/projects_page_summary/`

  // plugin buildResourceMethods
  static list = G.listProjectsPageSummarys

  static retrieve = G.retrieveProjectsPageSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/page_resources/projects_page_summary/']

  static listQ = <TSelect = ListProjectsPageSummarys200['results']>(
    props?: Utils.UseQueryOptions<ListProjectsPageSummarys200, unknown, TSelect> &
      Utils.Params<ListProjectsPageSummarysParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProjectsPageSummary>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProjectsPageSummary, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class APIActivitySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin_resources/api_activities/`

  // plugin buildResourceMethods
  static get = G.aPIActivity

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin_resources/api_activities/']

  static getQ = <TSelect = APIActivityViewOutput>(
    props: Utils.UseQueryOptions<APIActivityViewOutput, unknown, TSelect> & {
      params: APIActivityParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class AggregatedPaymentListRowSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin_resources/aggregated_payment_list_rows/`

  // plugin buildResourceMethods
  static get = G.aggregatedPaymentListRow

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin_resources/aggregated_payment_list_rows/']

  static getQ = <TSelect = AggregatedPaymentListRowViewOutput>(
    props: Utils.UseQueryOptions<AggregatedPaymentListRowViewOutput, unknown, TSelect> & {
      params: AggregatedPaymentListRowParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class CustomerSummarySpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin_resources/customer_summaries/`

  // plugin buildResourceMethods
  static get = G.customerSummary

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin_resources/customer_summaries/']

  static getQ = <TSelect = CustomerSummaryViewOutput>(
    props: Utils.UseQueryOptions<CustomerSummaryViewOutput, unknown, TSelect> & {
      params: CustomerSummaryParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetReadOnlyAuthCommandSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin_resources/get_read_only_auth_command/`

  // plugin buildResourceMethods
  static get = G.getReadOnlyAuthCommand

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin_resources/get_read_only_auth_command/']

  static getQ = <TSelect = GetReadOnlyAuthCommandViewOutput>(
    props: Utils.UseQueryOptions<GetReadOnlyAuthCommandViewOutput, unknown, TSelect> & {
      params: GetReadOnlyAuthCommandParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetAllowedQBBucketSearchesSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/get_allowed_qb_bucket_searches/`

  // plugin buildResourceMethods
  static get = G.getAllowedQBBucketSearches

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin/get_allowed_qb_bucket_searches/']

  static getQ = <TSelect = GetAllowedQBBucketSearchesViewOutput>(
    props: Utils.UseQueryOptions<GetAllowedQBBucketSearchesViewOutput, unknown, TSelect> & {
      params: GetAllowedQBBucketSearchesParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class AdminQBPullRequestSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/qb_pull_requests/`

  // plugin buildResourceMethods
  static list = G.listAdminQBPullRequests

  static retrieve = G.retrieveAdminQBPullRequest

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin/qb_pull_requests/']

  static listQ = <TSelect = ListAdminQBPullRequests200['results']>(
    props?: Utils.UseQueryOptions<ListAdminQBPullRequests200, unknown, TSelect> &
      Utils.Params<ListAdminQBPullRequestsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = AdminQBPullRequest>(
    id: string | null,
    props?: Utils.UseQueryOptions<AdminQBPullRequest, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class AdminQBPushRequestSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/qb_push_requests/`

  // plugin buildResourceMethods
  static list = G.listAdminQBPushRequests

  static retrieve = G.retrieveAdminQBPushRequest

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin/qb_push_requests/']

  static listQ = <TSelect = ListAdminQBPushRequests200['results']>(
    props?: Utils.UseQueryOptions<ListAdminQBPushRequests200, unknown, TSelect> &
      Utils.Params<ListAdminQBPushRequestsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = AdminQBPushRequest>(
    id: string | null,
    props?: Utils.UseQueryOptions<AdminQBPushRequest, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class AdminTransactionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/transactions/`

  // plugin buildResourceMethods
  static list = G.listAdminTransactions

  static retrieve = G.retrieveAdminTransaction

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin/transactions/']

  static listQ = <TSelect = ListAdminTransactions200['results']>(
    props?: Utils.UseQueryOptions<ListAdminTransactions200, unknown, TSelect> &
      Utils.Params<ListAdminTransactionsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = AdminTransaction>(
    id: string | null,
    props?: Utils.UseQueryOptions<AdminTransaction, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class AgaveAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/accounts/`

  // plugin buildResourceMethods
  static list = G.listAgaveAccounts

  static retrieve = G.retrieveAgaveAccount

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin/accounts/']

  static listQ = <TSelect = ListAgaveAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListAgaveAccounts200, unknown, TSelect> &
      Utils.Params<ListAgaveAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = AgaveAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<AgaveAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class BusinessNoteSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/business_notes/`

  // plugin buildResourceMethods
  static list = G.listBusinessNotes

  static create = G.createBusinessNote

  static retrieve = G.retrieveBusinessNote

  static update = G.partialUpdateBusinessNote

  static delete = G.destroyBusinessNote

  // plugin addResourceDependencies
  static downstream = ['/admin/business_notes/']

  // plugin addQuerySpecs
  static key = () => ['/admin/business_notes/']

  static listQ = <TSelect = ListBusinessNotes200['results']>(
    props?: Utils.UseQueryOptions<ListBusinessNotes200, unknown, TSelect> &
      Utils.Params<ListBusinessNotesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BusinessNote>(
    id: string | null,
    props?: Utils.UseQueryOptions<BusinessNote, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BusinessNoteCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<BusinessNotePatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useDelete = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: []) => {
        return this.delete(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class EmailPixelSpec {
  // plugin addBaseUrl
  static baseUrl = (trackingId: string) => `/email/trackers/${trackingId}/pixel.gif`

  // plugin buildResourceMethods
  static get = G.emailPixel

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = (trackingId: string) => ['/email/trackers/', `${trackingId}`, '/pixel.gif']

  static getQ = <TSelect = EmailPixelViewOutput>(
    trackingId: string,
    props?: Utils.UseQueryOptions<EmailPixelViewOutput, unknown, TSelect> & Utils.Params<never>,
  ) => {
    const queryFn = () => this.get(trackingId)
    const queryKey: Utils.QueryKey = [...this.key(trackingId), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class BeamBalanceTransactionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/transfers/beam_balance_transactions/`

  // plugin buildResourceMethods
  static list = G.listBeamBalanceTransactions

  static retrieve = G.retrieveBeamBalanceTransaction

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/transfers/beam_balance_transactions/']

  static listQ = <TSelect = ListBeamBalanceTransactions200['results']>(
    props?: Utils.UseQueryOptions<ListBeamBalanceTransactions200, unknown, TSelect> &
      Utils.Params<ListBeamBalanceTransactionsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BeamBalanceTransaction>(
    id: string | null,
    props?: Utils.UseQueryOptions<BeamBalanceTransaction, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class TransferSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/transfers/transfers/`

  // plugin buildResourceMethods
  static list = G.listTransfers

  static create = G.createTransfer

  static retrieve = G.retrieveTransfer

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/agave/raw_qbo_links/',
    '/banking/get_statement_pdf/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/transfers/transfers/']

  static listQ = <TSelect = ListTransfers200['results']>(
    props?: Utils.UseQueryOptions<ListTransfers200, unknown, TSelect> &
      Utils.Params<ListTransfersParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Transfer>(
    id: string | null,
    props?: Utils.UseQueryOptions<Transfer, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: TransferCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GetHubspotVisitorTokenSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/chat/get_hubspot_visitor_token/`

  // plugin buildResourceMethods
  static get = G.getHubspotVisitorToken

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/chat/get_hubspot_visitor_token/']

  static getQ = <TSelect = GetHubspotVisitorTokenViewOutput>(
    props?: Utils.UseQueryOptions<GetHubspotVisitorTokenViewOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class CardTransactionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/expense_card/card_transactions/`

  // plugin buildResourceMethods
  static list = G.listCardTransactions

  static retrieve = G.retrieveCardTransaction

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/expense_card/card_transactions/']

  static listQ = <TSelect = ListCardTransactions200['results']>(
    props?: Utils.UseQueryOptions<ListCardTransactions200, unknown, TSelect> &
      Utils.Params<ListCardTransactionsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CardTransaction>(
    id: string | null,
    props?: Utils.UseQueryOptions<CardTransaction, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ExpenseCardSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/expense_card/expense_cards/`

  // plugin buildResourceMethods
  static list = G.listExpenseCards

  static retrieve = G.retrieveExpenseCard

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/expense_card/expense_cards/']

  static listQ = <TSelect = ListExpenseCards200['results']>(
    props?: Utils.UseQueryOptions<ListExpenseCards200, unknown, TSelect> &
      Utils.Params<ListExpenseCardsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ExpenseCard>(
    id: string | null,
    props?: Utils.UseQueryOptions<ExpenseCard, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class CardProgramSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/expense_card/card_programs/`

  // plugin buildResourceMethods
  static list = G.listCardPrograms

  static retrieve = G.retrieveCardProgram

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/expense_card/card_programs/']

  static listQ = <TSelect = ListCardPrograms200['results']>(
    props?: Utils.UseQueryOptions<ListCardPrograms200, unknown, TSelect> &
      Utils.Params<ListCardProgramsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CardProgram>(
    id: string | null,
    props?: Utils.UseQueryOptions<CardProgram, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetAccountLoginURLSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/capitalos/get_account_login_url/`

  // plugin buildResourceMethods
  static get = G.getAccountLoginURL

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/capitalos/get_account_login_url/']

  static getQ = <TSelect = GetAccountLoginURLViewOutput>(
    props?: Utils.UseQueryOptions<GetAccountLoginURLViewOutput, unknown, TSelect> &
      Utils.Params<never>,
  ) => {
    const queryFn = () => this.get()
    const queryKey: Utils.QueryKey = [...this.key(), 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetInvoiceCtaSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/gtm/get_invoice_cta/`

  // plugin buildResourceMethods
  static get = G.getInvoiceCta

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/gtm/get_invoice_cta/']

  static getQ = <TSelect = GetInvoiceCtaViewOutput>(
    props: Utils.UseQueryOptions<GetInvoiceCtaViewOutput, unknown, TSelect> & {
      params: GetInvoiceCtaParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetInvoiceListCtaSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/gtm/get_invoice_list_cta/`

  // plugin buildResourceMethods
  static get = G.getInvoiceListCta

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/gtm/get_invoice_list_cta/']

  static getQ = <TSelect = GetInvoiceListCtaViewOutput>(
    props: Utils.UseQueryOptions<GetInvoiceListCtaViewOutput, unknown, TSelect> & {
      params: GetInvoiceListCtaParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class ExpenseCodeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/accounting/expense_codes/`

  // plugin buildResourceMethods
  static list = G.listExpenseCodes

  static retrieve = G.retrieveExpenseCode

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/projects/cost_codes/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/accounting/expense_codes/']

  static listQ = <TSelect = ListExpenseCodes200['results']>(
    props?: Utils.UseQueryOptions<ListExpenseCodes200, unknown, TSelect> &
      Utils.Params<ListExpenseCodesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ExpenseCode>(
    id: string | null,
    props?: Utils.UseQueryOptions<ExpenseCode, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class IncomeCodeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/accounting/income_codes/`

  // plugin buildResourceMethods
  static list = G.listIncomeCodes

  static retrieve = G.retrieveIncomeCode

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/income_codes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/projects/cost_codes/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/accounting/income_codes/']

  static listQ = <TSelect = ListIncomeCodes200['results']>(
    props?: Utils.UseQueryOptions<ListIncomeCodes200, unknown, TSelect> &
      Utils.Params<ListIncomeCodesParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = IncomeCode>(
    id: string | null,
    props?: Utils.UseQueryOptions<IncomeCode, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class ItemSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/accounting/items/`

  // plugin buildResourceMethods
  static list = G.listItems

  static retrieve = G.retrieveItem

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/items/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/page_resources/expense_list_rows/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/accounting/items/']

  static listQ = <TSelect = ListItems200['results']>(
    props?: Utils.UseQueryOptions<ListItems200, unknown, TSelect> & Utils.Params<ListItemsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Item>(
    id: string | null,
    props?: Utils.UseQueryOptions<Item, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class LedgerAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/accounting/ledger_accounts/`

  // plugin buildResourceMethods
  static list = G.listLedgerAccounts

  static retrieve = G.retrieveLedgerAccount

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/ledger_accounts/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/agave/raw_qbo_links/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/page_resources/expense_list_rows/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/accounting/ledger_accounts/']

  static listQ = <TSelect = ListLedgerAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListLedgerAccounts200, unknown, TSelect> &
      Utils.Params<ListLedgerAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = LedgerAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<LedgerAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class BillOcrRawResultLineItemSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/ocr/bill_ocr_raw_result_line_items/`

  // plugin buildResourceMethods
  static list = G.listBillOcrRawResultLineItems

  static retrieve = G.retrieveBillOcrRawResultLineItem

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/ocr/bill_ocr_raw_result_line_items/']

  static listQ = <TSelect = ListBillOcrRawResultLineItems200['results']>(
    props?: Utils.UseQueryOptions<ListBillOcrRawResultLineItems200, unknown, TSelect> &
      Utils.Params<ListBillOcrRawResultLineItemsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BillOcrRawResultLineItem>(
    id: string | null,
    props?: Utils.UseQueryOptions<BillOcrRawResultLineItem, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class BillOcrRawResultSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/ocr/bill_ocr_raw_results/`

  // plugin buildResourceMethods
  static list = G.listBillOcrRawResults

  static retrieve = G.retrieveBillOcrRawResult

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/ocr/bill_ocr_raw_results/']

  static listQ = <TSelect = ListBillOcrRawResults200['results']>(
    props?: Utils.UseQueryOptions<ListBillOcrRawResults200, unknown, TSelect> &
      Utils.Params<ListBillOcrRawResultsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = BillOcrRawResult>(
    id: string | null,
    props?: Utils.UseQueryOptions<BillOcrRawResult, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class MfaRequirementSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/mfa/mfa_requirements/`

  // plugin buildResourceMethods
  static list = G.listMfaRequirements

  static retrieve = G.retrieveMfaRequirement

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/mfa/mfa_requirements/']

  static listQ = <TSelect = ListMfaRequirements200['results']>(
    props?: Utils.UseQueryOptions<ListMfaRequirements200, unknown, TSelect> &
      Utils.Params<ListMfaRequirementsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = MfaRequirement>(
    id: string | null,
    props?: Utils.UseQueryOptions<MfaRequirement, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class GetAuthRequestLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/oauth/get_auth_request_link/`

  // plugin buildResourceMethods
  static get = G.getAuthRequestLink

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/oauth/get_auth_request_link/']

  static getQ = <TSelect = GetAuthRequestLinkViewOutput>(
    props: Utils.UseQueryOptions<GetAuthRequestLinkViewOutput, unknown, TSelect> & {
      params: GetAuthRequestLinkParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class CompanyCamAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/oauth/companycam_accounts/`

  // plugin buildResourceMethods
  static list = G.listCompanyCamAccounts

  static create = G.createCompanyCamAccount

  static retrieve = G.retrieveCompanyCamAccount

  static singleton = G.retrieveSingletonCompanyCamAccount

  // plugin addResourceDependencies
  static downstream = [
    '/companycam/companycam_links/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/oauth/companycam_accounts/']

  static listQ = <TSelect = ListCompanyCamAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListCompanyCamAccounts200, unknown, TSelect> &
      Utils.Params<ListCompanyCamAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CompanyCamAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<CompanyCamAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = CompanyCamAccount>(
    props?: Utils.UseQueryOptions<CompanyCamAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: CompanyCamAccountCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ProcoreAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/oauth/procore_accounts/`

  // plugin buildResourceMethods
  static list = G.listProcoreAccounts

  static create = G.createProcoreAccount

  static retrieve = G.retrieveProcoreAccount

  static singleton = G.retrieveSingletonProcoreAccount

  // plugin addResourceDependencies
  static downstream = [
    '/companycam/companycam_links/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/oauth/procore_accounts/']

  static listQ = <TSelect = ListProcoreAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListProcoreAccounts200, unknown, TSelect> &
      Utils.Params<ListProcoreAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = ProcoreAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<ProcoreAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = ProcoreAccount>(
    props?: Utils.UseQueryOptions<ProcoreAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ProcoreAccountCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class QboAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/oauth/qbo_accounts/`

  // plugin buildResourceMethods
  static list = G.listQboAccounts

  static create = G.createQboAccount

  static retrieve = G.retrieveQboAccount

  static singleton = G.retrieveSingletonQboAccount

  // plugin addResourceDependencies
  static downstream = [
    '/companycam/companycam_links/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/oauth/qbo_accounts/']

  static listQ = <TSelect = ListQboAccounts200['results']>(
    props?: Utils.UseQueryOptions<ListQboAccounts200, unknown, TSelect> &
      Utils.Params<ListQboAccountsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = QboAccount>(
    id: string | null,
    props?: Utils.UseQueryOptions<QboAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  static singletonQ = <TSelect = QboAccount>(
    props?: Utils.UseQueryOptions<QboAccount, unknown, TSelect>,
  ) => {
    const queryFn = () => this.singleton()
    const queryKey: Utils.QueryKey = [...this.key(), 'singleton']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: QboAccountCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class CompanyCamLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/companycam/companycam_links/`

  // plugin buildResourceMethods
  static list = G.listCompanyCamLinks

  static retrieve = G.retrieveCompanyCamLink

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/companycam/companycam_links/']

  static listQ = <TSelect = ListCompanyCamLinks200['results']>(
    props?: Utils.UseQueryOptions<ListCompanyCamLinks200, unknown, TSelect> &
      Utils.Params<ListCompanyCamLinksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = CompanyCamLink>(
    id: string | null,
    props?: Utils.UseQueryOptions<CompanyCamLink, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class DocumentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/companycam/documents/`

  // plugin buildResourceMethods
  static list = G.listDocuments

  static retrieve = G.retrieveDocument

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/companycam/documents/']

  static listQ = <TSelect = ListDocuments200['results']>(
    props?: Utils.UseQueryOptions<ListDocuments200, unknown, TSelect> &
      Utils.Params<ListDocumentsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Document>(
    id: string | null,
    props?: Utils.UseQueryOptions<Document, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class PhotoSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/companycam/photos/`

  // plugin buildResourceMethods
  static list = G.listPhotos

  static retrieve = G.retrievePhoto

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/companycam/photos/']

  static listQ = <TSelect = ListPhotos200['results']>(
    props?: Utils.UseQueryOptions<ListPhotos200, unknown, TSelect> & Utils.Params<ListPhotosParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = Photo>(
    id: string | null,
    props?: Utils.UseQueryOptions<Photo, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class RenofiAdSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/partnerships/renofi_ads/`

  // plugin buildResourceMethods
  static list = G.listRenofiAds

  static create = G.createRenofiAd

  static retrieve = G.retrieveRenofiAd

  static update = G.partialUpdateRenofiAd

  // plugin addResourceDependencies
  static downstream = ['/partnerships/renofi_ad_clicks/', '/partnerships/renofi_ads/']

  // plugin addQuerySpecs
  static key = () => ['/partnerships/renofi_ads/']

  static listQ = <TSelect = ListRenofiAds200['results']>(
    props?: Utils.UseQueryOptions<ListRenofiAds200, unknown, TSelect> &
      Utils.Params<ListRenofiAdsParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = RenofiAd>(
    id: string | null,
    props?: Utils.UseQueryOptions<RenofiAd, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: RenofiAdCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<RenofiAdPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GetRenofiAdSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/partnerships/get_renofi_ad/`

  // plugin buildResourceMethods
  static get = G.getRenofiAd

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/partnerships/get_renofi_ad/']

  static getQ = <TSelect = GetRenofiAdViewOutput>(
    props: Utils.UseQueryOptions<GetRenofiAdViewOutput, unknown, TSelect> & {
      params: GetRenofiAdParams
    },
  ) => {
    const queryFn = () => this.get(props.params)
    const queryKey: Utils.QueryKey = [...this.key(), props.params, 'get']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, queryFn, select, queryKey }
  }

  // plugin addMutationSpecs
}

export class RenofiAdClickSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/partnerships/renofi_ad_clicks/`

  // plugin buildResourceMethods
  static list = G.listRenofiAdClicks

  static retrieve = G.retrieveRenofiAdClick

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/partnerships/renofi_ad_clicks/']

  static listQ = <TSelect = ListRenofiAdClicks200['results']>(
    props?: Utils.UseQueryOptions<ListRenofiAdClicks200, unknown, TSelect> &
      Utils.Params<ListRenofiAdClicksParams>,
  ) => {
    const queryFn = () => this.list(props?.params)
    const queryKey: Utils.QueryKey = [...this.key(), props?.params ?? '<none>']
    const select = props?.select ?? ((x) => x.results as TSelect)
    return { ...props, select, queryFn, queryKey }
  }

  static retrieveQ = <TSelect = RenofiAdClick>(
    id: string | null,
    props?: Utils.UseQueryOptions<RenofiAdClick, unknown, TSelect>,
  ) => {
    const queryFn = () => this.retrieve(id as string)
    const queryKey: Utils.QueryKey = [...this.key(), id ?? '<none>']
    const select = props?.select ?? ((x) => x as TSelect)
    return { ...props, enabled: !!id, select, queryFn, queryKey }
  }

  // plugin addMutationSpecs
}

export class SetBusinessStatusSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/businesses/set_business_status/`

  // plugin buildResourceMethods
  static post = G.setBusinessStatus

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/accounting/income_codes/',
    '/accounting/items/',
    '/accounting/ledger_accounts/',
    '/admin/accounts/',
    '/admin/business_notes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/payment_sources/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/budget_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/external_communication_configs/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/businesses/stripe_connected_account_links/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_configs/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_programs/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
    '/ocr/bill_ocr_raw_result_line_items/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/onboarding/onboarding_surveys/',
    '/onboarding/terms_of_service_acceptances/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
    '/projects/cost_types/',
    '/projects/general_project_configs/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/businesses/set_business_status/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SetBusinessStatusInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class BulkCreateInvoicePaymentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/invoice_payments/bulk/`

  // plugin buildResourceMethods
  static post = G.bulkCreateInvoicePayment

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/billing/invoice_payments/bulk/']

  // plugin addMutationSpecs
}

export class BumpInvoiceVersionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/bump_invoice_version/`

  // plugin buildResourceMethods
  static post = G.bumpInvoiceVersion

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/vendor_list_rows/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/bump_invoice_version/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: BumpInvoiceVersionInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SendInvoiceEmailSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/send_invoice_email/`

  // plugin buildResourceMethods
  static post = G.sendInvoiceEmail

  // plugin addResourceDependencies
  static downstream = [
    '/billing/bills/',
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoices_page_summarys/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/send_invoice_email/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SendInvoiceEmailInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SendLienWaiverEmailSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/billing/send_sign_lien_waiver_email/`

  // plugin buildResourceMethods
  static post = G.sendLienWaiverEmail

  // plugin addResourceDependencies
  static downstream = [
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/businesses/outbound_invites/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/invoice_list_rows/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/billing/send_sign_lien_waiver_email/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SendLienWaiverEmailInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class LoginSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/login/`

  // plugin buildResourceMethods
  static create = G.createLogin

  // plugin addResourceDependencies
  static downstream = ['/admin_resources/get_read_only_auth_command/']

  // plugin addQuerySpecs
  static key = () => ['/users/login/']

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: LoginCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class LogoutV2Spec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/logout/`

  // plugin buildResourceMethods
  static create = G.createLogoutV2

  // plugin addResourceDependencies
  static downstream = ['/admin_resources/get_read_only_auth_command/']

  // plugin addQuerySpecs
  static key = () => ['/users/logout/']

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: LogoutV2Create]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class AuthenticateMagicLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/authenticate_magic_link/`

  // plugin buildResourceMethods
  static post = G.authenticateMagicLink

  // plugin addResourceDependencies
  static downstream = ['/admin_resources/get_read_only_auth_command/']

  // plugin addQuerySpecs
  static key = () => ['/users/authenticate_magic_link/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: AuthenticateMagicLinkViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GenerateMagicLinkTokenSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/generate_magic_link_token/`

  // plugin buildResourceMethods
  static post = G.generateMagicLinkToken

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/users/generate_magic_link_token/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: GenerateMagicLinkTokenViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class PasswordResetCompletionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/password_reset_completions/`

  // plugin buildResourceMethods
  static post = G.passwordResetCompletion

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/businesses/employees/',
    '/users/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/users/password_reset_completions/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: PasswordResetCompletionViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class PasswordResetInitializationSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/password_reset_initializations/`

  // plugin buildResourceMethods
  static post = G.passwordResetInitialization

  // plugin addResourceDependencies
  static downstream = [
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/invoice_list_rows/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/users/password_reset_initializations/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: PasswordResetInitializationViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class VerifyEmailSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/users/verify_email/`

  // plugin buildResourceMethods
  static post = G.verifyEmail

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/users/email_verifications/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/users/verify_email/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: VerifyEmailViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class MicrodepositAttemptSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/microdeposit_attempts/`

  // plugin buildResourceMethods
  static create = G.createMicrodepositAttempt

  // plugin addResourceDependencies
  static downstream = [
    '/banking/bank_accounts/',
    '/banking/microdeposit_requirements/',
    '/banking/transaction_accounts/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/banking/microdeposit_attempts/']

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: MicrodepositAttemptCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SetupIntentSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/setup_intents/`

  // plugin buildResourceMethods
  static create = G.createSetupIntent

  static update = G.partialUpdateSetupIntent

  // plugin addResourceDependencies
  static downstream = [
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_bank_name/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/external_payment_sources/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/billing/payment_sources/',
    '/mfa/mfa_requirements/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/banking/setup_intents/']

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SetupIntentCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }

  static useUpdate = (...staticProps: [id: string]) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: Partial<SetupIntentPatch>]) => {
        return this.update(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SetInboundTransfersEnabledSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/set_inbound_transfers_enabled/`

  // plugin buildResourceMethods
  static post = G.setInboundTransfersEnabled

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/customer_summaries/',
    '/banking/bank_accounts/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/banking/set_inbound_transfers_enabled/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SetInboundTransfersEnabledViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SetMfaRequirementSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/banking/set_mfa_requirement/`

  // plugin buildResourceMethods
  static post = G.setMfaRequirement

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/mfa/mfa_requirements/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/banking/set_mfa_requirement/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SetMfaRequirementInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class FileUploadSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/files/uploads/`

  // plugin buildResourceMethods
  static create = G.createFileUpload

  // plugin addResourceDependencies
  static downstream = [
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/project_expenses/',
    '/compliance/compliance_documents/',
    '/estimates/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/projects/contract_files/',
    '/projects/contracts/',
    '/projects/logos/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/files/uploads/']

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: FileUploadCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GenerateStripeOnboardingClientSecretSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/generate_stripe_onboarding_client_secret/`

  // plugin buildResourceMethods
  static post = G.generateStripeOnboardingClientSecret

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/onboarding/generate_stripe_onboarding_client_secret/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: GenerateStripeOnboardingClientSecretViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SetOnboardingPushedToStripeSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/set_onboarding_pushed_to_stripe/`

  // plugin buildResourceMethods
  static post = G.setOnboardingPushedToStripe

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/onboarding/set_onboarding_pushed_to_stripe/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SetOnboardingPushedToStripeViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SetPayerEnabledSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/onboarding/set_payer_enabled/`

  // plugin buildResourceMethods
  static post = G.setPayerEnabled

  // plugin addResourceDependencies
  static downstream = [
    '/accounting/expense_codes/',
    '/accounting/income_codes/',
    '/accounting/items/',
    '/accounting/ledger_accounts/',
    '/admin/accounts/',
    '/admin/business_notes/',
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin/transactions/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/api_activities/',
    '/admin_resources/customer_summaries/',
    '/admin_resources/get_read_only_auth_command/',
    '/agave/accounts/',
    '/agave/get_project_import_request/',
    '/agave/get_updated_token_status/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qb_pull_requests/',
    '/agave/qb_push_requests/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_note_file_links/',
    '/attachments/internal_notes/',
    '/attachments/note_file_links/',
    '/attachments/note_mention_links/',
    '/attachments/notes/',
    '/banking/bank_accounts/',
    '/banking/cards/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/get_statement_pdf/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/external_payment_sources/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_approval_workflow_requirements/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_approval_rules/',
    '/billing/invoice_intent_files/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoice_prefill_line_items/',
    '/billing/invoice_prefills/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/payment_sources/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/time_entries/',
    '/billing/vendor_billing_configs/',
    '/billing/work_completed_sources/',
    '/budgeting/budget_configs/',
    '/budgeting/get_project_budget_cost/',
    '/budgeting/project_budget_items/',
    '/budgeting/project_budgets/',
    '/businesses/',
    '/businesses/additional_contacts/',
    '/businesses/employees/',
    '/businesses/external_communication_configs/',
    '/businesses/outbound_invites/',
    '/businesses/payee_contacts/',
    '/businesses/payer_contacts/',
    '/businesses/stripe_connected_account_links/',
    '/capitalos/get_account_login_url/',
    '/chat/get_hubspot_visitor_token/',
    '/companycam/companycam_links/',
    '/companycam/documents/',
    '/companycam/photos/',
    '/compliance/compliance_documents/',
    '/compliance/compliance_settings_list_payee_overrides/',
    '/compliance/compliance_settings_lists/',
    '/compliance/compliance_status_summarys/',
    '/compliance/get_combined_compliance_settings_list_as_payee/',
    '/compliance/get_compliance_settings_list_for_payee/',
    '/compliance/payee_compliance_requirements/',
    '/compliance/requirement_lists/',
    '/estimates/',
    '/estimates/estimate_configs/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_estimate_cover_page_pdf_preview/',
    '/estimates/get_estimate_input_from_file/',
    '/estimates/get_next_estimate_number/',
    '/expense_card/card_programs/',
    '/expense_card/card_transactions/',
    '/expense_card/expense_cards/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/mfa/mfa_requirements/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
    '/ocr/bill_ocr_raw_result_line_items/',
    '/ocr/bill_ocr_raw_results/',
    '/onboarding/business_profiles/',
    '/onboarding/employee_profiles/',
    '/onboarding/onboarding_surveys/',
    '/onboarding/terms_of_service_acceptances/',
    '/onboarding/whoami/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/card_transaction_list_rows/',
    '/page_resources/client_list_rows/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/partnerships/get_renofi_ad/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/clients/',
    '/projects/contract_files/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/cost_codes/',
    '/projects/cost_types/',
    '/projects/general_project_configs/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/logos/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/onboarding/set_payer_enabled/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SetPayerEnabledViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SendClientInvoiceReceivedEmailViewSetSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/client_billing/send_client_invoice_received_email/`

  // plugin buildResourceMethods
  static post = G.sendClientInvoiceReceivedEmailViewSet

  // plugin addResourceDependencies
  static downstream = [
    '/billing/bills/',
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoices_page_summarys/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/client_billing/send_client_invoice_received_email/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SendClientInvoiceReceivedEmailViewSetInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class PublishEstimateSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/publish_estimate/`

  // plugin buildResourceMethods
  static post = G.publishEstimate

  // plugin addResourceDependencies
  static downstream = [
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/',
    '/estimates/estimate_cover_page_photos/',
    '/estimates/estimate_cover_pages/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/estimates/estimate_page_visits/',
    '/estimates/estimates_as_payee_page_summarys/',
    '/estimates/get_next_estimate_number/',
    '/page_resources/estimates_page_summarys/',
    '/page_resources/invoice_list_rows/',
    '/partnerships/renofi_ad_clicks/',
    '/partnerships/renofi_ads/',
    '/projects/get_contract_item_edit_restrictions/',
    '/tasks/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/estimates/publish_estimate/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: PublishEstimateInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class SendEstimateReminderSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/estimates/send_estimate_reminder/`

  // plugin buildResourceMethods
  static post = G.sendEstimateReminder

  // plugin addResourceDependencies
  static downstream = [
    '/billing/invoices/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/estimates/estimate_email_views/',
    '/estimates/estimate_emails/',
    '/page_resources/invoice_list_rows/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/estimates/send_estimate_reminder/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: SendEstimateReminderInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ArchiveBankAccountSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/archive_bank_account/`

  // plugin buildResourceMethods
  static post = G.archiveBankAccount

  // plugin addResourceDependencies
  static downstream = [
    '/admin_resources/customer_summaries/',
    '/banking/bank_accounts/',
    '/banking/controlled_payee_bank_accounts/',
    '/banking/microdeposit_requirements/',
    '/banking/payee_bank_accounts/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/admin/archive_bank_account/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ArchiveBankAccountViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class GetQBBucketsSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/admin/get_qb_buckets/`

  // plugin buildResourceMethods
  static post = G.getQBBuckets

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/admin/get_qb_buckets/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: GetQBBucketsViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class TrackEmailLinkClickedSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/email/track_email_link_clicked/`

  // plugin buildResourceMethods
  static post = G.trackEmailLinkClicked

  // plugin addResourceDependencies
  static downstream = []

  // plugin addQuerySpecs
  static key = () => ['/email/track_email_link_clicked/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: TrackEmailLinkClickedViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class CategorizeTransactionSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/transfers/categorize_transaction/`

  // plugin buildResourceMethods
  static post = G.categorizeTransaction

  // plugin addResourceDependencies
  static downstream = [
    '/admin/qb_pull_requests/',
    '/admin/qb_push_requests/',
    '/admin_resources/aggregated_payment_list_rows/',
    '/admin_resources/customer_summaries/',
    '/agave/invoice_outbound_syncs_v2/',
    '/agave/qbo_links/',
    '/agave/raw_qbo_links/',
    '/attachments/internal_notes/',
    '/attachments/notes/',
    '/billing/bills/',
    '/billing/contract_progress_summarys/',
    '/billing/free_form_invoice_line_items/',
    '/billing/get_expense_metrics/',
    '/billing/get_invoice_card_fee/',
    '/billing/get_invoice_file/',
    '/billing/get_lien_waiver_payment_blockers/',
    '/billing/get_lien_waiver_pdf_preview_v2/',
    '/billing/get_next_invoice_number/',
    '/billing/get_retainage_values/',
    '/billing/invoice_action_requests/',
    '/billing/invoice_approval_actions/',
    '/billing/invoice_intents/',
    '/billing/invoice_line_item_markup_links/',
    '/billing/invoice_line_items/',
    '/billing/invoice_payment_groups/',
    '/billing/invoice_payments/',
    '/billing/invoice_permissions/',
    '/billing/invoices/',
    '/billing/lien_waivers/',
    '/billing/predict_uncategorized_expense_details/',
    '/billing/prime_contract_invoice_email_views/',
    '/billing/prime_contract_invoice_emails/',
    '/billing/prime_contract_invoice_payment_page_visits/',
    '/billing/project_expense_permissions/',
    '/billing/project_expenses/',
    '/billing/project_progress_summary_v2s/',
    '/billing/schedule_of_values_item_progress_summaries/',
    '/billing/work_completed_sources/',
    '/budgeting/get_project_budget_cost/',
    '/estimates/',
    '/estimates/get_next_estimate_number/',
    '/gtm/get_invoice_cta/',
    '/gtm/get_invoice_list_cta/',
    '/page_resources/bill_list_rows/',
    '/page_resources/bills_page_summarys/',
    '/page_resources/client_list_rows/',
    '/page_resources/expense_list_rows/',
    '/page_resources/gc_subcontract_inline_line_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/page_resources/invoice_payment_fee_list_rows/',
    '/page_resources/invoices_page_summarys/',
    '/page_resources/project_list_rows/',
    '/page_resources/projects_page_summary/',
    '/page_resources/vendor_list_rows/',
    '/projects/',
    '/projects/change_orders/',
    '/projects/contract_items/',
    '/projects/contracts/',
    '/projects/get_contract_item_edit_restrictions/',
    '/projects/project_permissions/',
    '/projects/removal_change_orders/',
    '/projects/retainage_releases/',
    '/tasks/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/transfers/categorize_transaction/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: CategorizeTransactionInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class InitiateBillOcrSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/ocr/initiate_bill_ocr/`

  // plugin buildResourceMethods
  static post = G.initiateBillOcr

  // plugin addResourceDependencies
  static downstream = ['/ocr/bill_ocr_raw_result_line_items/', '/ocr/bill_ocr_raw_results/']

  // plugin addQuerySpecs
  static key = () => ['/ocr/initiate_bill_ocr/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: InitiateBillOcrInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class MfaAttemptSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/mfa/mfa_attempts/`

  // plugin buildResourceMethods
  static create = G.createMfaAttempt

  // plugin addResourceDependencies
  static downstream = ['/banking/bank_accounts/', '/mfa/mfa_requirements/']

  // plugin addQuerySpecs
  static key = () => ['/mfa/mfa_attempts/']

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: MfaAttemptCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ESignatureSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/e_signatures/`

  // plugin buildResourceMethods
  static create = G.createESignature

  // plugin addResourceDependencies
  static downstream = ['/billing/get_invoice_file/', '/billing/lien_waivers/']

  // plugin addQuerySpecs
  static key = () => ['/e_signatures/']

  // plugin addMutationSpecs
  static useCreate = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ESignatureCreate]) => {
        return this.create(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class UpdateAuthTokenSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/oauth/update_auth_token/`

  // plugin buildResourceMethods
  static post = G.updateAuthToken

  // plugin addResourceDependencies
  static downstream = [
    '/companycam/companycam_links/',
    '/oauth/companycam_accounts/',
    '/oauth/get_auth_request_link/',
    '/oauth/procore_accounts/',
    '/oauth/qbo_accounts/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/oauth/update_auth_token/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: UpdateAuthTokenViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class ClickLinkSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/analytics/click_link/`

  // plugin buildResourceMethods
  static post = G.clickLink

  // plugin addResourceDependencies
  static downstream = ['/partnerships/renofi_ad_clicks/']

  // plugin addQuerySpecs
  static key = () => ['/analytics/click_link/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: ClickLinkViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}

export class UpdateMoneyMovementSpec {
  // plugin addBaseUrl
  static baseUrl = () => `/dev/update_money_movement/`

  // plugin buildResourceMethods
  static post = G.updateMoneyMovement

  // plugin addResourceDependencies
  static downstream = [
    '/admin/transactions/',
    '/banking/get_statement_pdf/',
    '/banking/transaction_accounts/',
    '/billing/bills/',
    '/billing/invoice_payments/',
    '/billing/invoices/',
    '/fees/cash_back_payments/',
    '/fees/yield_payments/',
    '/page_resources/bill_list_rows/',
    '/page_resources/invoice_list_rows/',
    '/transfers/beam_balance_transactions/',
    '/transfers/transfers/',
  ]

  // plugin addQuerySpecs
  static key = () => ['/dev/update_money_movement/']

  // plugin addMutationSpecs
  static usePost = (...staticProps: []) => {
    const qc = useQueryClient()
    return useMutation({
      mutationFn: (...props: [bodyProps: UpdateMoneyMovementViewInput]) => {
        return this.post(...staticProps, ...props).then((data) => {
          this.downstream.map((x) => qc.invalidateQueries([x]))
          return data
        })
      },
    })
  }
}
