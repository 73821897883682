import BigNumber from 'bignumber.js'

export function getFirstOpt<T extends Exclude<any, undefined>>(arr: readonly T[]): T | null {
  return arr.at(0) ?? null
}

export function getFirst<T extends Exclude<any, undefined>>(arr: readonly T[]): T {
  const firstOpt = getFirstOpt(arr)
  if (firstOpt === null) throw new Error('Expected element in array')
  return firstOpt
}

// eslint-disable-next-line mosaic-js/unnamed-args
export function sortBy<T>(
  list: T[],
  keyFn: (t: T) => string | number | BigNumber,
  order: 'asc' | 'desc' = 'asc',
): T[] {
  return list.sort((a, b) => {
    const sorting = order === 'asc' ? 1 : -1

    if (a == null) return 1
    const aValue = keyFn(a)
    if (b == null) return -1
    const bValue = keyFn(b)

    if (typeof aValue === 'string' && typeof bValue === 'string')
      return aValue.toLowerCase().localeCompare(bValue.toLowerCase()) * sorting

    if (aValue instanceof BigNumber && bValue instanceof BigNumber)
      return aValue.eq(bValue) ? 0 : aValue.lt(bValue) ? -sorting : sorting

    try {
      return aValue === bValue ? 0 : aValue < bValue ? -sorting : sorting
    } catch (e) {
      return 1
    }
  })
}

export function unionConcat<T1, T2>(arr1: T1[], arr2: T2[]): (T1 | T2)[] {
  const base = [] as (T1 | T2)[]
  return base.concat(arr1).concat(arr2)
}

export function isEmptyOrIncludes<T>(arr: readonly T[], item: T) {
  if (arr.length === 0) return true
  return arr.includes(item)
}
